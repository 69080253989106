import { IActionContext, ICoreContext } from '@msdyn365-commerce/core';
import { getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { IMFIATPInventoryStatusEntity, IMFIATPInventoryStatusResponse, IMFICartLine, IMFICLDeliveryScheduleParameters } from '../actions/CoreProductDataServiceEntities.g';
import { IBuyboxGetDimensionPricingActionData } from '../data-actions/buybox-get-dimension-pricing-action';
import { IBuyboxGetDimensionVariantAvailabilityActionData } from '../data-actions/buybox-get-dimension-variants-availability-action';
import { IBuyboxViewPropsExtended, IMFIATPInventoryStatusResponseExtended } from '../modules/mfrm-buybox/types';
import { IMfrmBuyboxProps } from '../modules/mfrm-buybox/mfrm-buybox.props.autogenerated';
import { IMfrmBuyBoxData } from '../modules/mfrm-buybox/mfrm-buybox.view';
import _getDeliveryByMessage from './get-delivery-by-message';
import _getEyebrowMessage from './get-eyebrow-message';
import _getInventoryQuantity from './get-inventory-quantity';
// tslint:disable-next-line: max-line-length no-any
export const _coreProductATPCall = async (
    props: IBuyboxViewPropsExtended & IMfrmBuyboxProps<IMfrmBuyBoxData>,
    setCoreProductATPData: React.Dispatch<any>,
    recordId: number,
    itemId: string | undefined,
    setATPCallMessage: React.Dispatch<any>,
    setIsAtpCallReturned: React.Dispatch<boolean>,
    isCached?: boolean
) => {
    const { context } = props;
    const cookies = new Cookies();
    const zipcode = cookies.get('zip_code');
    if (zipcode === "" || zipcode === undefined) {
        return;
    }
    const itemLinesArray = [];
    if (props.data.product.result) {
        const itemLines: IMFICartLine = {
            ItemId: itemId,
            Quantity: 1,
            VariantRecordId: recordId?.toString()
        };

        itemLinesArray.push(itemLines);
        const currentDate = Date.now();
        const requestBody: IMFICLDeliveryScheduleParameters = {
            InventoryType: 'Delivery',
            Weeks: props.context.app.config.weeksPDPforATPCall,
            StoreId: '',
            Page: 'pdp',
            RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
            ZipCode: zipcode,
            ItemLines: itemLinesArray
        };
        await _getDeliveryByMessage(requestBody, context, isCached)
            .then(result => {
                setIsAtpCallReturned(true);
                if (result !== undefined && result?.ATPInventoryData && result?.ATPInventoryData.length > 0) {
                    setCoreProductATPData(result?.ATPInventoryData);
                } else {
                    // VSI Customization - START
                    setCoreProductATPData([]);
                    // VSI Customization - End
                }
                setATPCallMessage(result?.Message);
            })
            .catch(e => {
                console.log('Error', e);
            });
    }
};

export const _coreProductATPCallForInventoryStatus = async (
    props: IBuyboxViewPropsExtended & IMfrmBuyboxProps<IMfrmBuyBoxData>,
    recordId: number,
    itemId: string | undefined,
    allVariants: IBuyboxGetDimensionVariantAvailabilityActionData[],
    index: number
) => {
    const { context } = props;
    // this commented code will removed when general data appeared
    const cookies = new Cookies();
    const zipcode = cookies.get('zip_code');
    if (zipcode === "" || zipcode === undefined) {
        return;
    }
    const itemLinesArray = [];
    if (props.data.product.result) {
        const itemLines: IMFICartLine = {
            ItemId: itemId,
            Quantity: 1,
            VariantRecordId: recordId?.toString()
        };
        itemLinesArray.push(itemLines);
        const currentDate = Date.now();
        const requestBody: IMFICLDeliveryScheduleParameters = {
            InventoryType: 'Delivery',
            Weeks: props.context.app.config.weeksPDPforATPCall,
            StoreId: '',
            Page: 'pdp',
            RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
            ZipCode: zipcode, // '77047',
            ItemLines: itemLinesArray
        };
        const getInventoryQuantity = _getInventoryQuantity(requestBody, context);
        getInventoryQuantity.then((result) => {
            if (result !== undefined && result?.ATPInventoryStatusData && result?.ATPInventoryStatusData.length > 0) {
                const coreProductAtpData = result?.ATPInventoryStatusData;
                coreProductAtpData?.length && coreProductAtpData.map((item: IMFIATPInventoryStatusEntity) => {
                    // TODO: remove console logs after testing
                    if (item.Available === 'YES' && item.Quantity !== undefined && parseInt(item.Quantity, 10) > 0) {
                        allVariants[index].isAvailable = true;
                        return true;
                    }
                    allVariants[index].isAvailable = false;
                    return false;
                });
            }
        }).catch((e) => {
            console.log('Error', e);
        });
    }
};

export const _getActivePrices = async (
    pricingData: IBuyboxGetDimensionPricingActionData[],
    ctx: IActionContext
): Promise<IBuyboxGetDimensionPricingActionData[]> => {
    const date = new Date();
    const productIds: number[] = [];
    for (let i = 0;i < pricingData.length;i++) {
        productIds.push(pricingData[i].productId);
    }
    await getActivePricesAsync(
        { callerContext: ctx },
        { ChannelId: ctx.requestContext.apiSettings.channelId, CatalogId: ctx.requestContext.apiSettings.catalogId },
        productIds,
        date,
        null,
        [],
        true
    ).then(dimPricesResult => {
        dimPricesResult?.map((item, index) => {
            pricingData[index].discountAmount = item.DiscountAmount;
        });
    });
    return pricingData;
};

export const _smallParcelProductATPCallForInventoryStatus = async (
    props: IBuyboxViewPropsExtended & IMfrmBuyboxProps<IMfrmBuyBoxData>,
    recordId: number,
    itemId: string | undefined,
    // tslint:disable-next-line: no-any
    SetoutOfStockSmallParcelState: React.Dispatch<any>,
    isCached?: boolean
) => {
    const { context } = props;
    // this commented code will removed when general data appeared
    const cookies = new Cookies();
    const zipcode = cookies.get('zip_code');
    if (zipcode === "" || zipcode === undefined) {
        return;
    }
    const itemLinesArray = [];
    if (props.data.product.result) {
        const itemLines: IMFICartLine = {
            ItemId: itemId,
            Quantity: 1,
            VariantRecordId: recordId.toString()
        };
        itemLinesArray.push(itemLines);
        const currentDate = Date.now();
        const requestBody: IMFICLDeliveryScheduleParameters = {
            InventoryType: 'Delivery',
            Weeks: props.context.app.config.weeksPDPforATPCall,
            StoreId: '',
            Page: 'pdp',
            RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
            ZipCode: zipcode, // '77047',
            ItemLines: itemLinesArray
        };
        await _getInventoryQuantity(requestBody, context, isCached)
            .then(result => {
                if (result !== undefined && result?.ATPInventoryStatusData && result?.ATPInventoryStatusData.length > 0) {
                    const coreProductAtpData = result?.ATPInventoryStatusData;
                    coreProductAtpData &&
                        coreProductAtpData.length &&
                        coreProductAtpData.map((item: IMFIATPInventoryStatusEntity) => {
                            // TODO: remove console logs after testing
                            if (item.Available === 'YES' && item.Quantity !== undefined && parseInt(item.Quantity, 10) > 0) {
                                // allVariants[index].isAvailable = true;
                                SetoutOfStockSmallParcelState(false);
                                return true;
                            } else {
                                SetoutOfStockSmallParcelState(true);
                            }
                            // allVariants[index].isAvailable = false;
                            return false;
                        });
                }
            })
            .catch(e => {
                console.log('Error', e);
            });
    }
};

export const _coreProductATPCallForInventoryStatusForAllVariants = async (
    props: IMfrmBuyboxProps<IMfrmBuyBoxData>,
    recordIds: number[],
    itemId: string | undefined,
    allVariants: IBuyboxGetDimensionVariantAvailabilityActionData[]
): Promise<IMFIATPInventoryStatusResponseExtended | null> => {
    const { context } = props;
    // this commented code will removed when general data appeared
    const cookies = new Cookies();
    const zipcode: any = cookies.get('zip_code');
    if (zipcode === '' || zipcode === undefined) {
        return null;
    }
    const itemLinesArray = [];
    if (props.data.product.result) {
        for (let ridIter = 0;ridIter < recordIds.length;ridIter++) {
            const itemLines: IMFICartLine = {
                ItemId: itemId,
                Quantity: 1,
                VariantRecordId: recordIds[ridIter].toString()
            };
            itemLinesArray.push(itemLines);
        }

        const currentDate = Date.now();
        const requestBody: IMFICLDeliveryScheduleParameters = {
            InventoryType: 'Delivery',
            Weeks: props.context.app.config.weeksPDPforATPCall,
            StoreId: '',
            Page: 'pdp',
            RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
            ZipCode: zipcode, // '77047',
            ItemLines: itemLinesArray
        };

        const result: IMFIATPInventoryStatusResponse | null = await _getInventoryQuantity(requestBody, context, true);
        if (result !== undefined && result?.ATPInventoryStatusData && result?.ATPInventoryStatusData.length > 0) {
            const coreProductAtpData = result?.ATPInventoryStatusData;
            coreProductAtpData && coreProductAtpData.length && coreProductAtpData.map((item: IMFIATPInventoryStatusEntity) => {

                for (let iter = 0;iter < allVariants.length;iter++) {
                    // TODO: remove console logs after testing
                    if (item.Available === 'YES' && item.Quantity !== undefined && parseInt(item.Quantity, 10) > 0 && allVariants[iter].variantID.toString() === item.VariantRecordId) {
                        allVariants[iter].isAvailable = true;
                        break;
                    }
                    else if (item.Available === 'NO' && allVariants[iter].variantID.toString() === item.VariantRecordId) {
                        allVariants[iter].isAvailable = false;
                        break;
                    }
                }
            });
        }
        const finalResult: IMFIATPInventoryStatusResponseExtended = {
            ...result,
            zipCode: requestBody?.ZipCode
        };
        return finalResult;
    }
    return null;
};

export const _smallParcelProductATPBooleanStatus = async (
    recordId: number,
    itemId: string | undefined,
    weeksPDPforATPCall: number | undefined,
    context: ICoreContext<{ [x: string]: any; }>
    // tslint:disable-next-line: no-any
): Promise<boolean | undefined> => {
    // this commented code will removed when general data appeared
    const cookies = new Cookies();
    const zipcode = cookies.get('zip_code');
    if (zipcode === "" || zipcode === undefined) {
        return false;
    }
    const itemLinesArray = [];
    const itemLines: IMFICartLine = {
        ItemId: itemId,
        Quantity: 1,
        VariantRecordId: recordId.toString()
    };
    itemLinesArray.push(itemLines);
    const currentDate = Date.now();
    const requestBody: IMFICLDeliveryScheduleParameters = {
        InventoryType: 'Delivery',
        Weeks: weeksPDPforATPCall ?? 6,
        StoreId: '',
        Page: 'pdp',
        RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
        ZipCode: zipcode, // '77047',
        ItemLines: itemLinesArray
    };
    return _getInventoryQuantity(requestBody, context, true)
        .then(result => {
            if (result !== undefined && result?.ATPInventoryStatusData && result?.ATPInventoryStatusData.length > 0) {
                const coreProductAtpData = result?.ATPInventoryStatusData[0];
                if (coreProductAtpData.Available === 'YES' && coreProductAtpData.Quantity !== undefined && parseInt(coreProductAtpData.Quantity, 10) > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        })
        .catch(e => {
            console.log('Error', e);
            return false;
        });
};