import { Tooltip, Rating } from 'mfcl';
import Info from 'mfcl/Icons/Info';
import React from 'react';
import { BuyboxCustomerSatisfactionScoreProps } from './buybox-customer-satisfaction-score.props';
import { Node } from '@msdyn365-commerce-modules/utilities';

export const BuyboxCustomerSatisfactionScore = ({
    unitsReturned,
    unitsSold,
    headingText,
    popoverText,
    tooltipHover
}: BuyboxCustomerSatisfactionScoreProps) => {
    if (!unitsSold) {
        return null;
    }

    const percentNotReturned: number = Math.round(100 - (Number(unitsReturned) / Number(unitsSold)) * 100);
    const unitsNotReturend = Number(unitsSold) - Number(unitsReturned);
    const avgRating = (unitsNotReturend * 5) / Number(unitsSold);
    return (
        <Node className='ms-buybox__csat-score'>
            <span>{headingText?.replace('{perNotReturned}', `${percentNotReturned}%`)}</span>
            <Tooltip trigger={<Info height={20} width={20} />} position='bottom-left' hover={tooltipHover}>
                <div className='ms-buybox__tooltip-content'>
                    <span>
                        {popoverText
                            ?.replace('{unitsNotReturend}', unitsNotReturend.toLocaleString())
                            ?.replace('{unitsSold}', Number(unitsSold).toLocaleString())}
                    </span>
                    <Node className='csat-rating-text'>
                        <Rating name='csat-score' defaultValue={avgRating} readOnly precision={0.5} />
                        <div>
                            <span>{`${percentNotReturned}%`}</span>
                            {`(${unitsNotReturend.toLocaleString()})`}
                        </div>
                    </Node>
                </div>
            </Tooltip>
        </Node>
    );
};

BuyboxCustomerSatisfactionScore.displayName = 'BuyboxCustomerSatisfactionScore';
