import { useEffect, useState } from "react";
import vwoSDK, { vwoAsyncInstance } from 'vwo-node-sdk';
import { getCookie, setCookie } from "../cookies/cookie-utils";
import uuid from 'uuid';

// Vwo's getSettingsFile method returns on abject and it isn't typed
interface IVwoSettingsResponse {
    accountId: number;
    campaignGroups: object;
    campaigns: any[];
    groups: object;
    isEventArchEnabled: boolean;
    sdkKey: string;
    version: number;
}

function getVwoUserId() {
    if (typeof window !== 'undefined') {
        let vwoUserId = getCookie('vwoUserId');

        if (!vwoUserId) {
            vwoUserId = uuid.v4();
            setCookie('vwoUserId', vwoUserId);
        }
        return vwoUserId;
    }
    return uuid.v4();
}

function useVwoClientInstance(accountId: string, sdkKey: string) {
    const [vwoClientInstance, setVWOClientInstance] = useState<any>(null);
    const [vwoUserId, setVwoUserId] = useState<any>(getVwoUserId());
    useEffect(() => {
        const initVwo = async () => {
            // NOTE: accountId and sdkKey from props.app.config
            // const accountId = '717583';
            // const sdkKey = 'ddd425ce4e9946c2ff2f252e4c6bfe48';
            if (!accountId || !sdkKey) {
                throw Error('Missing VWO settings');
            }
            const settingsFile = await vwoSDK.getSettingsFile(accountId, sdkKey);
            const clientInstance: vwoAsyncInstance = vwoSDK.launch({ settingsFile });
            if (clientInstance) {
                setVWOClientInstance(clientInstance);
                setVwoUserId(getVwoUserId());

                for (const campaign of (settingsFile as IVwoSettingsResponse).campaigns) {
                    clientInstance.activate(campaign.key, vwoUserId);
                }
            }
        };
        initVwo();
    }, []);

    return [vwoClientInstance as vwoAsyncInstance, vwoUserId];
}

export default useVwoClientInstance;