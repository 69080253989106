import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { IMFICLDeliveryScheduleParameters } from '../actions/CoreProductDataServiceEntities.g';
import getATPInventoryStatusAction, { GetATPInventoryStatusInput } from '../actions/get-sm-inventory-status.action';

const _getInventoryQuantity = async (
    request: IMFICLDeliveryScheduleParameters,
    context: ICoreContext<IGeneric<IAny>>,
    isCached?: boolean
) => {
    if (request) {
        const getATPInventoryStatusInput = new GetATPInventoryStatusInput(request, isCached);
        try {
            const result = await getATPInventoryStatusAction(getATPInventoryStatusInput, context.actionContext);
            if (result) {
                return result;
            }
        } catch (error) {
            console.log('Error', error);
        }
    }
    return null;
};

export default _getInventoryQuantity;
