import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { Alert, Button, format, getPayloadObject, getTelemetryAttributes, getTelemetryObject, Heading, ITelemetryContent, Node, TelemetryConstant } from '@msdyn365-commerce-modules/utilities';
import {
    AddToCartComponent, IAddToCartFailureResult, IAddToCartResources,
    PriceComponent, RatingComponent
} from '@msdyn365-commerce/components';
import MSDyn365, { getUrlSync, IImageSettings } from '@msdyn365-commerce/core';
import { ICartActionResult } from '@msdyn365-commerce/global-state';
import * as React from 'react';
import { getConfigureErrors, getGenericError, getQuantityError } from './utilities/error-utilities';

import {
    IBuyboxAddToCartViewProps, IBuyboxCommonData,
    IBuyboxErrorBlockProps, IBuyboxExtentedProps, IBuyboxKeyInPriceViewProps, IBuyboxShopSimilarLookViewProps, IBuyboxState,
    IProductDetails, ShopSimiliarButtonType
} from '@msdyn365-commerce-modules/buybox';

import { IBuyboxCallbacksLite, IBuyboxModulePropsExtended } from '../types';

export function getBuyBoxInventoryLabel(props: IBuyboxModulePropsExtended, productDetails?: IProductDetails): React.ReactElement | undefined {
    const { typeName } = props;
    const productAvailableQuantity = props.data.productAvailableQuantity?.result ? props.data.productAvailableQuantity?.result : productDetails?.productAvailableQuantity!;

    const className = _getClassNamePrefix(typeName);
    if (!productAvailableQuantity || !productAvailableQuantity.length) {
        return undefined;
    }
    if (productAvailableQuantity &&
        productAvailableQuantity.length &&
        productAvailableQuantity[0].StockLevelLabel) {
        const inventoryClassName = productAvailableQuantity[0].StockLevelCode ?
            `${className}__inventory-label ${className}__inventory-code-${productAvailableQuantity[0].StockLevelCode.toLowerCase()}` :
            `${className}__inventory-label`;
        return (<div className={`${className}__inventory-info`}><span className={inventoryClassName} >{productAvailableQuantity[0].StockLevelLabel}</span></div>);
    }

    return undefined;

}

export function getBuyboxProductTitle(props: IBuyboxModulePropsExtended, productDetails?: IProductDetails, brandName?: string): React.ReactElement | undefined {
    const {
        config: { titleHeadingTag = 'h1' },
        typeName
    } = props;
    const className = typeName && _getClassNamePrefix(typeName);

    const product = props.data.product?.result ? props.data.product.result : productDetails?.product!;
    const title = brandName ? `${brandName} ${product.Name}` : product.Name;
    return product && (
        <Heading
            className={`${className}__product-title`}
            headingTag={titleHeadingTag}
            text={title || ''}
        />
    );
}

export function getBuyboxProductDescription(props: IBuyboxModulePropsExtended, productDetails?: IProductDetails): React.ReactElement | undefined {
    const { typeName } = props;
    const className = _getClassNamePrefix(typeName);
    const product = props.data.product?.result ? props.data.product?.result : productDetails?.product!;

    return product && (
        <p className={`${className}__product-description`}>{product.Description}</p>
    );
}

let telemetryContent: ITelemetryContent;

// tslint:disable-next-line:max-func-body-length
export function getBuyboxAddToCart(props: IBuyboxModulePropsExtended, state: IBuyboxState, callbacks: IBuyboxCallbacksLite,
    defaultMinimumKeyInPrice: number, defaultMaximumKeyInPrice: number, productDetails?: IProductDetails): IBuyboxAddToCartViewProps {
    const {
        id,
        typeName,
        context,
        resources,
        config: { minimumKeyInPrice, maximumKeyInPrice }
    } = props;

    const product = props.data?.product?.result ? props.data.product.result : productDetails?.product!;
    const cart = props.data?.cart?.result ? props.data.cart.result : productDetails?.cart!;
    const productAvailableQuantity = props.data?.productAvailableQuantity?.result ? props.data.productAvailableQuantity.result : productDetails?.productAvailableQuantity!;

    const {
        quantity,
        errorState: {
            configureErrors,
            quantityError,
            customAmountError,
            otherError,
            errorHost
        },
        selectedProduct,
        isUpdatingDimension,
        isUpdatingDeliveryOptions,
        isServiceItem,
        keyInPriceAmount
    } = state;

    const productAvailability = state.productAvailableQuantity ?
        state.productAvailableQuantity.ProductAvailableQuantity :
        productAvailableQuantity && productAvailableQuantity.length ?
            productAvailableQuantity[0].ProductAvailableQuantity : undefined;

    const onAddToCartFailed = (result: IAddToCartFailureResult) => {
        let quantityErrorForState: string | undefined;
        let customAmountErrorForState: string | undefined;
        let otherErrorForState: string | undefined = getGenericError(result, cart, resources, context, product, productAvailability, undefined);

        if (result?.failureReason === 'OUTOFSTOCK') {
            quantityErrorForState = result.failureReason === 'OUTOFSTOCK' ? getQuantityError(result.stockLeft, resources) : undefined;
        } else if (result && result.failureReason === 'CARTACTIONFAILED'
            && result.cartActionResult
            && (result.cartActionResult.substatus === 'MAXQUANTITY' || result.cartActionResult?.substatus === 'QUANTITYLIMITS')) {
            quantityErrorForState = getGenericError(result, cart, resources, context, product, productAvailability, undefined);
            otherErrorForState = undefined; // prevent error duplication in otherError and quantityError
        }

        if (result?.failureReason === 'INVALIDCUSTOMAMOUNT') {
            if (keyInPriceAmount === undefined || keyInPriceAmount < (minimumKeyInPrice || defaultMinimumKeyInPrice)) {
                const formatedAmountLimit = props.context.cultureFormatter?.formatCurrency(minimumKeyInPrice || defaultMinimumKeyInPrice);
                customAmountErrorForState = resources.invalidSmallCustomAmountText?.replace('{minAmount}', formatedAmountLimit);
            } else if (keyInPriceAmount > (maximumKeyInPrice || defaultMaximumKeyInPrice)) {
                const formatedAmountLimit = props.context.cultureFormatter?.formatCurrency(maximumKeyInPrice || defaultMaximumKeyInPrice);
                customAmountErrorForState = resources.invalidLargeCustomAmountText?.replace('{maxAmount}', formatedAmountLimit);
            }
        }

        callbacks.updateErrorState({
            errorHost: 'ADDTOCART',
            quantityError: quantityErrorForState,
            configureErrors: result?.failureReason === 'MISSINGDIMENSION' ? getConfigureErrors(result?.missingDimensions, resources, product?.IsGiftCard) : {},
            customAmountError: customAmountErrorForState,
            otherError: otherErrorForState
        });
    };

    const dialogStrings: IAddToCartResources = {
        goToCartText: resources.buyBoxGoToCartText,
        continueShoppingText: resources.buyBoxContinueShoppingText,
        headerItemOneText: resources.buyBoxSingleItemText,
        headerItemFormatText: resources.buyBoxMultiLineItemFormatText,
        headerMessageText: resources.buyBoxHeaderMessageText,
        freePriceText: resources.priceFree,
        originalPriceText: resources.originalPriceText,
        currentPriceText: resources.currentPriceText,
        addedQuantityText: resources.addedQuantityText,
        closeNotificationLabel: resources.closeNotificationLabel
    };

    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            lg: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            xl: { q: `w=240&h=240&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };

    const isLoading = typeName !== 'quickview' && props.data.productAvailableQuantity?.status === 'LOADING';
    if (isLoading) {
        callbacks.changeUpdatingDimension(false);
    }

    const isLoadingDeliveryOptions = typeName !== 'quickview' && props.data.deliveryOptions?.status === 'LOADING';
    if (isLoadingDeliveryOptions && callbacks.changeUpdatingDeliveryOptions) {
        callbacks.changeUpdatingDeliveryOptions(false);
    }

    telemetryContent = getTelemetryObject(props.context.request.telemetryPageName!, props.typeName, props.telemetry);

    // check if the product delivery mode is electronic
    const emailDeliveryModeCode = props.context.actionContext.requestContext.channel?.EmailDeliveryModeCode;
    const deliveryOptions = props.data.deliveryOptions?.result;
    const isEmailDelivery = ArrayExtensions.hasElements(deliveryOptions?.DeliveryOptions) && deliveryOptions!.DeliveryOptions[0].Code === emailDeliveryModeCode;
    const className = _getClassNamePrefix(typeName);

    const onItemAddedToCart = (result: ICartActionResult) => {
        if (typeName === 'quickview') {
            callbacks.changeModalOpen(false);
        }
    };

    return {
        ContainerProps: {
            className: `${className}__add-to-cart-container`
        },
        button: product && (
            <AddToCartComponent
                addToCartText={resources.addToCartText}
                outOfStockText={resources.outOfStockText}
                navigationUrl={getUrlSync('cart', context.actionContext)}
                quantity={quantity}
                data={{ product: product, price: state.productPrice }}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToCartFailed}
                getSelectedProduct={selectedProduct}
                productAvailability={productAvailability}
                isLoading={isLoading}
                isUpdatingDimension={isUpdatingDimension}
                changeUpdatingDimension={callbacks.changeUpdatingDimension}
                isLoadingDeliveryOptions={isLoadingDeliveryOptions}
                isUpdatingDeliveryOptions={isUpdatingDeliveryOptions}
                changeUpdatingDeliveryOptions={callbacks.changeUpdatingDeliveryOptions}
                dialogStrings={dialogStrings}
                gridSettings={props.context.request.gridSettings}
                imageSettings={defaultImageSettings}
                telemetryContent={telemetryContent}
                isAddServiceItemToCart={isServiceItem}
                isPriceKeyedIn={state.isPriceKeyedIn}
                customPriceAmount={state.keyInPriceAmount}
                isCustomPriceSelected={state.isCustomPriceSelected}
                maximumKeyInPrice={props.config.maximumKeyInPrice}
                minimumKeyInPrice={props.config.minimumKeyInPrice}
                defaultMinimumKeyInPrice={defaultMinimumKeyInPrice}
                defaultMaximumKeyInPrice={defaultMaximumKeyInPrice}
                isOrderQuantityLimitsFeatureEnabled={_isOrderQuantityLimitsFeatureEnabled(props)}
                isAddEmailDeliveryItemToCart={isEmailDelivery}
                onAdd={onItemAddedToCart}
                isNavigationToCartPageDisabled={typeName === 'quickview'}
            />
        ),
        errorBlock: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                customAmountError={customAmountError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'ADDTOCART'}
            />
        )
    };
}

export function getBuyboxProductPrice(props: IBuyboxExtentedProps<IBuyboxCommonData>, state: IBuyboxState): React.ReactElement | undefined {
    const {
        id,
        typeName,
        context,
        resources
    } = props;

    return state.productPrice && (
        <PriceComponent
            id={id}
            typeName={typeName}
            context={context}
            data={{ price: state.productPrice }}
            freePriceText={resources.priceFree}
            originalPriceText={resources.originalPriceText}
            currentPriceText={resources.currentPriceText}
        />
    );
}

export function getBuyboxProductRating(props: IBuyboxExtentedProps<IBuyboxCommonData>, productDetails?: IProductDetails): React.ReactElement | undefined {
    const {
        id,
        typeName,
        context,
        resources
    } = props;

    const ratingsSummary = props.data.ratingsSummary?.result ? props.data.ratingsSummary?.result : productDetails?.ratingsSummary!;

    const ratingComponent = ratingsSummary?.averageRating && (
        <RatingComponent
            avgRating={ratingsSummary.averageRating || 0}
            readOnly={true}
            ariaLabel={format(resources?.averageRatingAriaLabel, ratingsSummary.averageRating, '5')}
            ratingCount={`${ratingsSummary.reviewsCount}`}
            data={{}}
            context={context}
            id={id}
            typeName={typeName}
        />
    ) || undefined;

    return ratingsSummary && ratingComponent && (
        ratingComponent
    );
}

export function getBuyboxShopSimilarButton(props: IBuyboxExtentedProps<IBuyboxCommonData>, buttonType: ShopSimiliarButtonType): IBuyboxShopSimilarLookViewProps {
    const {
        resources,
        context,
        data: {
            product: { result: product }
        },
        typeName
    } = props;

    const className = _getClassNamePrefix(typeName);
    const navigationUrl = (shopSimiliarButtonType: ShopSimiliarButtonType) => () => {
        const searchURL = getUrlSync('search', context?.actionContext);
        const separator = searchURL!.includes('?') ? '&' : '?';
        if (MSDyn365.isBrowser) {
            document.location.href = `${searchURL}${separator}productId=${product?.RecordId}&recommendation=${shopSimiliarButtonType}`;
        }
    };
    const telemetryText = buttonType === ShopSimiliarButtonType.Looks ? TelemetryConstant.ShopSimliarLooks : TelemetryConstant.ShopSimliarDescription;
    const payLoad = getPayloadObject('click', telemetryContent, telemetryText);
    const attribute = getTelemetryAttributes(telemetryContent, payLoad);
    const text = buttonType === ShopSimiliarButtonType.Looks ? resources.shopSimilarLooksText : resources.shopSimilarDescriptionText;

    return {
        ContainerProps: {
            className: `${className}__shopsimilar${buttonType}`
        },
        input: (
            <Button
                title={resources.shopSimilarLooksText}
                className={`${className}__shop-similar-${buttonType}-button`}
                aria-label={text}
                onClick={navigationUrl(buttonType)}
                {...attribute}
            >
                {text}
            </Button>
        )
    };
}

export function getBuyboxKeyInPrice(props: IBuyboxExtentedProps<IBuyboxCommonData>, state: IBuyboxState, callbacks: IBuyboxCallbacksLite): IBuyboxKeyInPriceViewProps {
    const {
        config,
        resources
    } = props;

    const
        {
            errorState: { }
        } = state;

    const className = _getClassNamePrefix(props.typeName);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        callbacks.updateKeyInPrice(value.length ? Number(value) : parseFloat(value));
    };

    return {
        ContainerProps: {
            className: `${className}__key_in_price`
        },
        LabelContainerProps: {
            tag: 'label',
            className: `${className}__key_in_price-label`,
            htmlFor: `${className}__key_in_price`
        },
        heading: (
            <div className={`${className}__key_in_price-label-heading`}>{resources.buyboxKeyInPriceLabelHeading}</div>
        ),
        input: (
            <input
                type='number'
                className={`${className}__key_in_price_custom-amount__input`}
                onChange={onChange}
                aria-label={resources.buyboxKeyInPriceLabelHeading}
                aria-valuemin={config.minimumKeyInPrice}
                aria-valuemax={config.minimumKeyInPrice}
                aria-valuenow={state.keyInPriceAmount}
                value={state.keyInPriceAmount}
                min={config.minimumKeyInPrice}
                max={config.minimumKeyInPrice}
            />
        )
    };

}

export const BuyboxErrorBlock: React.FC<IBuyboxErrorBlockProps> = ({ showError, configureErrors, quantityError, customAmountError, otherError, resources }) => {
    let errorMessages: (string | undefined)[] = [];

    errorMessages = Object.values(configureErrors).filter(message => message !== undefined);

    if (quantityError) {
        errorMessages.push(quantityError);
    }

    if (customAmountError) {
        errorMessages.push(customAmountError);
    }

    if (otherError) {
        errorMessages.push(otherError);
    }

    return (
        <Alert isOpen={showError && errorMessages.length > 0} color='danger' assertive={true} aria-label={resources.buyboxErrorMessageHeader} >
            <div className='msc-alert__header' aria-hidden='true'>
                <span className='msi-exclamation-triangle' />
                <span>{resources.buyboxErrorMessageHeader}</span>
            </div>
            {errorMessages.map((message, index) => {
                return (
                    <div key={index} className='msc-alert__line'>{message}</div>
                );
            })}
        </Alert>
    );
};

export function getQuantityLimitsMessages(props: IBuyboxModulePropsExtended, state: IBuyboxState): React.ReactElement | undefined {
    const {
        resources
    } = props;

    const
        {
            min,
            max
        } = state;

    if (!_isOrderQuantityLimitsFeatureEnabled(props)) {
        return undefined;
    }

    return (
        <Node className={'quantity_text_message'}>
            {_renderMaxLabel(max, resources.maxQuantityText)}
            {_renderMinLabel(min, resources.minQuantityText)}
        </Node>
    );
}

const _renderMaxLabel = (max: number | undefined, maxQuantityText: string, typeName?: string): React.ReactElement | undefined => {
    const className = _getClassNamePrefix(typeName ? typeName : '');
    if (max && max !== 0 && max !== Number.MAX_VALUE) {
        return (
            <p className={`${className}__minmax_label_text`}>
                {maxQuantityText?.replace('{max}', max.toString())}
            </p>
        );
    } else {
        return undefined;
    }
};

const _renderMinLabel = (min: number | undefined, minQuantityText: string, typeName?: string): JSX.Element | undefined => {
    const className = _getClassNamePrefix(typeName ? typeName : '');
    if (min && min > 1) {
        return (
            <p className={`${className}__minmax_label_text`}>
                {minQuantityText?.replace('{min}', min.toString())}
            </p>
        );
    } else {
        return undefined;
    }
};

const _isOrderQuantityLimitsFeatureEnabled = (props: IBuyboxModulePropsExtended): boolean | undefined => {
    const defaultOrderQuantityLimitsFeatureConfig = props.context?.request?.app?.platform?.enableDefaultOrderQuantityLimits;
    if (defaultOrderQuantityLimitsFeatureConfig === 'none') {
        return false;
    }

    if (defaultOrderQuantityLimitsFeatureConfig === 'all') {
        return true;
    }

    const customerInfo = props.data.customerInformation.result;
    return customerInfo &&
        ((defaultOrderQuantityLimitsFeatureConfig === 'b2b' && customerInfo.IsB2b) ||
            (defaultOrderQuantityLimitsFeatureConfig === 'b2c' && !customerInfo.IsB2b));
};

const _getClassNamePrefix = (typeName: string | undefined): string => {
    return typeName?.toLowerCase() === 'quickview' ? 'ms-quickView' : 'ms-buybox';
};
