import { AsyncResult, AttributeValue } from '@msdyn365-commerce/retail-proxy';

export function getProductSpecText(productSpecificationData: AsyncResult<AttributeValue[]> | undefined, Name: string): string | undefined {
    return productSpecificationData?.result?.find(e => e.Name?.trim().toLowerCase() === Name?.trim().toLowerCase())?.TextValue;
}

export function getProductSpecNumber( // Alt name: getProductSpecTextToNumber
    productSpecificationData: AsyncResult<AttributeValue[]> | undefined,
    Name: string
): number | undefined {
    const rawValue = getProductSpecText(productSpecificationData, Name);
    if (typeof rawValue === 'undefined') {
        return NaN;
    } else {
        return parseFloat(rawValue);
    }
}

export function getProductSpecBoolean(
    productSpecificationData: AsyncResult<AttributeValue[]> | undefined,
    Name: string
): boolean | undefined {
    return productSpecificationData?.result?.find(e => e.Name?.trim().toLowerCase() === Name?.trim().toLowerCase())?.BooleanValue;
}