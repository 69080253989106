import classnames from 'classnames';
import React, { useState } from 'react';

import {
    getPayloadObject,
    getTelemetryAttributes,
    IPopupProps,
    ITelemetryContent,
    Popup
} from '@msdyn365-commerce-modules/utilities';
import { PriceComponent } from '@msdyn365-commerce/components';
import MsDyn365, {
    IComponent,
    IComponentProps,
    IGridSettings,
    IImageSettings,
    msdyn365Commerce,
    TelemetryEvent
} from '@msdyn365-commerce/core';
import { getCartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import { CommerceProperty, ProductAvailableQuantity, ProductDimension, ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { navLinkKeywordRemoval } from '../../../../../Utilities/plp-url-utils';
import { getProductURLForUtag, getProductSizeForUTag } from '../../../../../modules/mfrm-buybox/mfrm-buybox';
import { getAttributeValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getUtagProductCategory } from '../../../../../Utilities/get-utag-product-category';
import { getUtagVariantId } from '../../../../../Utilities/get-utag-variant-id';
import { getSetCartProductPrice, ICartProductPrice } from '../../../../../Utilities/cart-utils';
import useVwoClientInstance from '../../../../../common/hooks/useVwoClientInstance';

export interface IAddToCartComponentProps extends IComponentProps<{ product: SimpleProduct; price?: ProductPrice; productPrice?: ProductPrice }> {
    className?: string;
    addToCartText: string;
    addToCartProductsText: string;
    outOfStockText: string;
    disabled?: boolean;
    quantity?: number;
    navigationUrl?: string;
    productAvailability?: ProductAvailableQuantity;
    getSelectedProduct?: Promise<SimpleProduct | null>;
    imageSettings?: IImageSettings;
    gridSettings?: IGridSettings;
    isLoading?: boolean;
    isUpdatingDimension?: boolean;
    isLoadingDeliveryOptions?: boolean;
    isUpdatingDeliveryOptions?: boolean;
    isAddServiceItemToCart?: boolean;
    isAddEmailDeliveryItemToCart?: boolean;
    isPriceKeyedIn?: boolean;
    customPriceAmount?: number;
    isOrderQuantityLimitsFeatureEnabled?: boolean;
    productIdPrefix?: string;
    dialogStrings?: {
        goToCartText: string;
        continueShoppingText: string;
        headerItemOneText: string;
        headerItemFormatText: string;
        headerMessageText: string;
        freePriceText: string;
        originalPriceText: string;
        currentPriceText: string;
    };
    telemetryContent?: ITelemetryContent;

    isCustomPriceSelected?: boolean;
    maximumKeyInPrice?: number;
    minimumKeyInPrice?: number;
    defaultMaximumKeyInPrice?: number;
    defaultMinimumKeyInPrice?: number;
    /** VSI Customization - Start */
    outOfStockBooleanValue?: boolean;
    outOfStockSmallParcel?: boolean;
    mfrmOutOfStockCustomText?: string;
    mfrmContinueShoppingText?: string;
    mfrmOutOfStockSmallParcell?: boolean;
    mfrmAddToCartDisableByState?: boolean;
    mfrmContinueShoppingLink?: IMfrmCategoryPath;
    itemOutOfMarket?: boolean;
    variantOutOfStock?: boolean;
    extensionProps?: CommerceProperty[];
    allProductVariants?: SimpleProduct[];
    productCategoryName?: string | undefined;
    productBrand?: string | undefined;
    successfulATC?: boolean;
    variantIdForTracking?: string;
    adjustableItem?: any;
    pillow?: any;
    protector?: any;
    updateSuccessfulATC?(): void;
    /** VSI Customization - End */
    onAdd?(result: ICartActionResult): void;
    onError?(result: IAddToCartFailureResult): void;
    changeUpdatingDimension?(isUpdatingDimension: boolean): void;
    changeUpdatingDeliveryOptions?(isUpdatingDeliveryOptions: boolean): void;
    allVariantOOS: boolean;
}

/** VSI Customization - Start */
export interface IMfrmCategoryPath {
    Name?: string;
    Url?: string;
}

export interface IMfrmAtpObject {
    itemId: string;
    recordId: string;
    date: string;
    zipCode: string;
    weeks: number;
}
/** VSI Customization - End */

export declare type ICartActionFailureReason =
    | 'EMPTYINPUT'
    | 'MISSINGDIMENSION'
    | 'OUTOFSTOCK'
    | 'CARTACTIONFAILED'
    | 'INVALIDCUSTOMAMOUNT';
export interface IAddToCartFailureResult {
    failureReason: ICartActionFailureReason;

    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

export interface IAddtoCartComponent extends IComponent<IAddToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps) => void;
}

export interface UTAGAdjBaseAddToCart {
    event: string;
    enhanced_action: string;
    event_category: string;
    event_action: string;
    event_label: string;
    event_noninteraction: string;
    product_id: string[];
    product_name: string[];
    product_price: string[]
    product_quantity: string[];
    product_url: string[];
    product_image_url: string[];
    product_size: (string | undefined)[];
    product_list_name: string[];
    product_list_position: string[];
    product_brand?: string[];
    product_category?: string[];
    product_variant?: (string | undefined)[];
}
export interface UTAGCompleteYourBedPillowAddToCart {
    event: string;
    enhanced_action: string;
    event_category: string;
    event_action: string;
    event_label: string;
    event_noninteraction: string;
    product_id: string[];
    product_name: string[];
    product_price: string[]
    product_quantity: string[];
    product_url: string[];
    product_image_url: string[];
    product_size: (string | undefined)[];
    product_brand?: string[];
    product_category?: string[];
    product_variant?: (string | undefined)[];
}
// tslint:disable: no-any
const onClick = async (
    _event: React.MouseEvent<HTMLElement>,
    props: IAddToCartComponentProps,
    setDisabled: (disabled: boolean) => void,
    openModal: (opened: boolean) => void,
    setSuccess: () => void
): Promise<void> => {
    const cartError = addToCartError(props);
    let productToAdd = props.data.product;
    const productBrand = `${props.productBrand}`;
    const productPrice = props.data.price;
    const productCategory = `${props.productCategoryName}`;
    // @ts-ignore
    if ('utag' in window) {
        // @ts-ignore
        // tslint:disable-next-line: object-literal-key-quotes
        utag.link({
            event: 'add-to-cart',
            enhanced_action: 'add',
            event_category: 'ecommerce',
            event_action: 'add to cart',
            event_label: `${productToAdd?.ItemId}: ${productToAdd?.Name}`,
            event_noninteraction: 'false',
            product_id: [`${productToAdd?.ItemId}`],
            product_name: [`${productToAdd?.Name}`],
            product_category: [productCategory],
            product_brand: [productBrand],
            product_variant: [`${props?.variantIdForTracking}`],
            product_price: [`${productPrice?.CustomerContextualPrice}`],
            product_quantity: [`${props.quantity}`],
            product_url: [`${getProductURLForUtag(productToAdd, props.context)}`],
            product_image_url: [`${productToAdd?.PrimaryImageUrl}`],
            product_size: [getProductSizeForUTag(productToAdd)]
        });
        if (props?.adjustableItem?.foundation) {
            const { ItemId, Name, CustomerContextualPrice, PrimaryImageUrl, Dimensions, RecordId, MasterProductId } = props.adjustableItem.foundation;
            const listPosition = props.adjustableItem.listPosition;
            const productSpecificationData = await getAttributeValuesAsync(
                { callerContext: props.context.actionContext, queryResultSettings: {} },
                RecordId,
                props.context.request.apiSettings.channelId,
                props.context.request.apiSettings.catalogId || 0
            );


            const dimension = Dimensions?.find((Dimension: { DimensionTypeValue: number }) => Dimension.DimensionTypeValue === 3);
            const product_size = dimension?.DimensionValue?.Value;

            const utag_obj: UTAGAdjBaseAddToCart = {
                event: 'add-to-cart',
                enhanced_action: 'add',
                event_category: 'ecommerce',
                event_action: 'add to cart',
                event_label: `${ItemId}: ${Name}`,
                event_noninteraction: 'false',
                product_id: [`${ItemId}`],
                product_category: await getUtagProductCategory(
                    props.context.actionContext,
                    props.context.request.apiSettings.channelId,
                    props.context.request.apiSettings.catalogId,
                    RecordId
                ),
                product_name: [`${Name}`],
                product_price: [`${CustomerContextualPrice}`],
                product_quantity: ['1'],
                product_url: [getProductURLForUtag(props.adjustableItem.foundation, props.context)],
                product_image_url: [`${PrimaryImageUrl}`],
                product_size: [
                    product_size
                ],
                product_list_name: ['PDP Add Base/Foundation'],
                product_list_position: [`${listPosition}`],
                product_variant: (MasterProductId && ItemId) ? await getUtagVariantId(MasterProductId, props.context.request.apiSettings.channelId, props.context.actionContext, RecordId, ItemId) : undefined
            };


            const brand = productSpecificationData.find(e => e.Name === 'Brand')?.TextValue;
            if (brand) {
                utag_obj.product_brand = [brand];
            }

            // @ts-ignore
            utag.link(utag_obj);
        }
        if (props?.pillow?.pillow) {
            const { ItemId, Name, CustomerContextualPrice, PrimaryImageUrl, Dimensions, RecordId, MasterProductId } = props.pillow.pillow;
            const productSpecificationData = await getAttributeValuesAsync(
                { callerContext: props.context.actionContext, queryResultSettings: {} },
                RecordId,
                props.context.request.apiSettings.channelId,
                props.context.request.apiSettings.catalogId || 0
            );
            const dimension = Dimensions?.find((Dimension: { DimensionTypeValue: number }) => Dimension.DimensionTypeValue === 3);
            const product_size = dimension?.DimensionValue?.Value;
            const utag_obj: UTAGCompleteYourBedPillowAddToCart = {
                event: 'add-to-cart',
                enhanced_action: 'add',
                event_category: 'ecommerce',
                event_action: 'add to cart',
                event_label: `${ItemId}: ${Name}`,
                event_noninteraction: 'false',
                product_id: [`${ItemId}`],
                product_category: await getUtagProductCategory(
                    props.context.actionContext,
                    props.context.request.apiSettings.channelId,
                    props.context.request.apiSettings.catalogId,
                    RecordId
                ),
                product_name: [`${Name}`],
                product_price: [`${CustomerContextualPrice}`],
                product_quantity: ['1'],
                product_url: [getProductURLForUtag(props.pillow.pillow, props.context)],
                product_image_url: [`${PrimaryImageUrl}`],
                product_size: [
                    product_size
                ],
                product_variant: (MasterProductId && ItemId) ? await getUtagVariantId(MasterProductId, props.context.request.apiSettings.channelId, props.context.actionContext, RecordId, ItemId) : undefined
            };
            const brand = productSpecificationData.find(e => e.Name === 'Brand')?.TextValue;
            if (brand) {
                utag_obj.product_brand = [brand];
            }
            // @ts-ignore
            utag.link(utag_obj);
        }
        if (props?.protector?.protector) {
            const { ItemId, Name, CustomerContextualPrice, PrimaryImageUrl, Dimensions, RecordId, MasterProductId } = props.protector.protector;
            const productSpecificationData = await getAttributeValuesAsync(
                { callerContext: props.context.actionContext, queryResultSettings: {} },
                RecordId,
                props.context.request.apiSettings.channelId,
                props.context.request.apiSettings.catalogId || 0
            );
            const dimension = Dimensions?.find((Dimension: { DimensionTypeValue: number }) => Dimension.DimensionTypeValue === 3);
            const product_size = dimension?.DimensionValue?.Value;
            const utag_obj: UTAGCompleteYourBedPillowAddToCart = {
                event: 'add-to-cart',
                enhanced_action: 'add',
                event_category: 'ecommerce',
                event_action: 'add to cart',
                event_label: `${ItemId}: ${Name}`,
                event_noninteraction: 'false',
                product_id: [`${ItemId}`],
                product_category: await getUtagProductCategory(
                    props.context.actionContext,
                    props.context.request.apiSettings.channelId,
                    props.context.request.apiSettings.catalogId,
                    RecordId
                ),
                product_name: [`${Name}`],
                product_price: [`${CustomerContextualPrice}`],
                product_quantity: ['1'],
                product_url: [getProductURLForUtag(props.protector.protector, props.context)],
                product_image_url: [`${PrimaryImageUrl}`],
                product_size: [
                    product_size
                ],
                product_variant: (MasterProductId && ItemId) ? await getUtagVariantId(MasterProductId, props.context.request.apiSettings.channelId, props.context.actionContext, RecordId, ItemId) : undefined
            };
            const brand = productSpecificationData.find(e => e.Name === 'Brand')?.TextValue;
            if (brand) {
                utag_obj.product_brand = [brand];
            }
            // @ts-ignore
            utag.link(utag_obj);
        }

    }
    if (cartError) {
        propagateError(props, cartError);
        return;
    }
    if (!(props.getSelectedProduct === undefined)) {
        productToAdd = (await props.getSelectedProduct) || props.data.product;
        // if props.getSelectedProduct has master product, than take it from data.product
        if (!productToAdd?.MasterProductId) {
            productToAdd = props.data.product;
        }
    }

    const productsToAdd: any = [
        {
            product: productToAdd,
            count: props.quantity,
            availableQuantity: props.productAvailability?.AvailableQuantity,
            additionalProperties: { orderQuantityLimitsFeatureIsEnabled: props.isOrderQuantityLimitsFeatureEnabled },
            enableStockCheck: props.context.app.config.enableStockCheck,
            isPriceKeyedIn: props.isPriceKeyedIn,
            customPrice: props.customPriceAmount,
            isAddEmailDeliveryItemToCart: props.isAddEmailDeliveryItemToCart
        }, ...(props.adjustableItem?.foundation ? [{
            product: props.adjustableItem?.foundation,
            count: props.adjustableItem?.foundation.quantity
        }] : []), ...(props.pillow?.pillow ? [{
            product: props.pillow?.pillow,
            count: props.quantity
        }] : []), ...(props.protector?.protector ? [{
            product: props.protector?.protector,
            count: props.quantity
        }] : [])
    ];
    const cartState = await getCartState(props.context.actionContext);
    // VSI CUSTOMIZATION - starts
    // Add product separately clear and the delivery information on retail server, so GetDeliveryOptions charge calculation works correctly
    const cart = cartState.cart;
    await cartState.clearCartLinesDeliveryInformation({ cart });
    productPriceHandler(productPrice);
    // VSI CUSTOMIZATION - ends
    await cartState
        .addProductsToCart(productsToAdd)
        .then(result => {
            if (result.status === 'SUCCESS') {
                if (props.dialogStrings && props.context.app.config.addToCartBehavior === 'showModal') {
                    setDisabled(false);
                    openModal(true);
                } else if (
                    MsDyn365.isBrowser &&
                    props.navigationUrl &&
                    (props.context.app.config.addToCartBehavior === undefined || props.context.app.config.addToCartBehavior === 'goToCart')
                ) {
                    // MsDyn365.isBrowser && window.location.assign(props.navigationUrl);
                    if (MsDyn365.isBrowser) {
                        window.location.href = props.navigationUrl;
                    }
                    // VSI CUSTOMIZATION - starts
                    setDisabled(true);
                    // VSI CUSTOMIZATION - ends
                }
                propagateResult(props, result);
                setSuccess();
            } else {
                propagateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: result });
                setDisabled(false);
            }
        })
        .catch(error => console.log('error[addProductToCart]', error));
};

const AddToCartComponentActions = {
    onClick: onClick
};

/*** VSI Customization - Buy side Save data custom event for check price mismatch details. * ticket - 95129 End. */
    const productPriceHandler = (productPrice?: ProductPrice) => {
    const getCartProductPriceArray = getSetCartProductPrice('get');
    const priceDetails: ICartProductPrice[] = [{variantRecordId: productPrice?.ProductId, price: productPrice?.AdjustedPrice}];
    getSetCartProductPrice('set', [...getCartProductPriceArray, ...priceDetails]);
};
// tslint:disable: cyclomatic-complexity
// tslint:disable: max-func-body-length
// tslint:disable: react-no-dangerous-html
const AddToCart: React.FC<IAddToCartComponentProps> = (props: IAddToCartComponentProps) => {
    const [disabled, setDisabled] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    // VWO Tracking
    const {vwoAccountId, vwoSdkKey} = props.context.app.config;
    const [ vwoClientInstance, vwoUserId ] = useVwoClientInstance((vwoAccountId ? vwoAccountId :'717583') , (vwoSdkKey ? vwoSdkKey : 'a537da7250fc410f83f9e8589efebe73'));
    const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
            if( vwoClientInstance ){
                const campaignKey = null;
                const userId = vwoUserId;
                vwoClientInstance.track(campaignKey, userId, 'addToCartCTA');
            }
        const setSuccess = props.updateSuccessfulATC ? props.updateSuccessfulATC : () => null;
        await AddToCartComponentActions.onClick(event, props, setDisabled, setModalOpen, setSuccess);
    };
    const priceComponent = props.data.price ? (
        <PriceComponent
            data={{ price: props.data.price }}
            context={props.context}
            id={props.id}
            typeName={props.typeName}
            freePriceText={props.dialogStrings?.freePriceText}
            originalPriceText={props.dialogStrings?.originalPriceText}
            currentPriceText={props.dialogStrings?.currentPriceText}
        />
    ) : (
        ''
    );

    const popupProps: IPopupProps = {
        context: props.context,
        className: 'msc-add-to-cart',
        id: props.id,
        typeName: props.typeName,
        data: { product: props.data.product, price: props.data.price },
        dialogStrings: props.dialogStrings,
        imageSettings: props.imageSettings,
        gridSettings: props.context.request?.gridSettings,
        productQuantity: props.quantity !== undefined ? props.quantity : 1,
        priceComponent: priceComponent,
        navigationUrl: props.navigationUrl,
        modalOpen: modalOpen,
        setModalOpen: setModalOpen,
        telemetryContent: props.telemetryContent
    };

    const renderModalPopup = <Popup {...popupProps} />;
    const label = getLinkText(props);
    const payload = getPayloadObject(TelemetryEvent.AddToCart, props.telemetryContent!, label, '');
    const attributes = getTelemetryAttributes(props.telemetryContent!, payload);

    // Todo: bug#22002 - Will be resolved later on - temp fix
    // const classToApply = props.mfrmOutOfStockSmallParcell ? 'out-of-stock msc-add-to-cart' : 'msc-add-to-cart';
    const classToApply = 'msc-add-to-cart';
    const OOMClass = props.itemOutOfMarket || props.variantOutOfStock || props.mfrmOutOfStockSmallParcell || props.allVariantOOS ? 'out-of-market disabled' : '';
    let disabledSmallParcel = false;
    // tslint:disable-next-line: max-line-length

    if (
        (props.disabled !== undefined ? props.disabled : false) ||
        disabled ||
        isIntermediateState(props) ||
        shouldShowOutOfStock(props, false) ||
        (props.mfrmAddToCartDisableByState !== undefined ? props.mfrmAddToCartDisableByState : false) ||
        props.itemOutOfMarket ||
        props.variantOutOfStock
    ) {
        disabledSmallParcel = true;
    }

    return (
        <div className='atc-button-container-redesign'>
            {renderModalPopup}
            <button
                className={classnames(classToApply, OOMClass, props.className)}
                aria-label={label}
                {...attributes}
                onClick={onClickHandler}
                /** VSI Customization - Start */
                disabled={props.mfrmOutOfStockSmallParcell || disabledSmallParcel || props.allVariantOOS}
            >
                {props.mfrmOutOfStockSmallParcell || props.allVariantOOS ? props.mfrmOutOfStockCustomText : label}
            </button>
            {props.mfrmOutOfStockSmallParcell && (
                <a
                    className='ms-buybox__continue-shopping-link'
                    href={navLinkKeywordRemoval(props.mfrmContinueShoppingLink?.Url!, props.context.request)}
                >
                    {props.mfrmContinueShoppingText}
                </a>
            )}
            {/** VSI Customization - End */}
        </div>
    );
};

// Set default props
AddToCart.defaultProps = {
    quantity: 1
};

export const getLinkText = (props: IAddToCartComponentProps): string => {
    if (props.itemOutOfMarket || props.variantOutOfStock) {
        return 'Item not available';
    }
    let productToAdd = props.data.product;
    if (!(props.getSelectedProduct === undefined)) {
        props.getSelectedProduct.
            then((product) => {
                productToAdd = product || props.data.product;
            }).
            catch((err) => console.log(err));
        // if props.getSelectedProduct has master product, than take it from data.product
        if (!productToAdd?.MasterProductId) {
            productToAdd = props.data.product;
        }
    }
    const getCount = [productToAdd, ...(props.adjustableItem.foundation ? [props.adjustableItem.foundation] : []), ...(props.pillow?.pillow ? [props.pillow.pillow] : []), ...(props.protector?.protector ? [props.protector.protector] : [])].length;
    return shouldShowOutOfStock(props, false) ? props.outOfStockText : (getCount > 1 ? props.addToCartProductsText?.replace('{itemcount}', getCount.toString()) : props.addToCartText);
};

const addToCartError = (props: IAddToCartComponentProps): IAddToCartFailureResult | undefined => {
    const {
        data,
        productAvailability,
        isCustomPriceSelected,
        customPriceAmount,
        maximumKeyInPrice,
        minimumKeyInPrice,
        defaultMaximumKeyInPrice = 100,
        defaultMinimumKeyInPrice = 10
    } = props;

    if (!data || !data.product.RecordId) {
        // No product exists, won't be able to add to cart
        return { failureReason: 'EMPTYINPUT' };
    }

    if (data.product?.Dimensions) {
        const missingDimensions = data.product.Dimensions.filter(
            dimension => !(dimension.DimensionValue && dimension.DimensionValue?.Value)
        );

        if (missingDimensions?.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to add to cart
            return { failureReason: 'MISSINGDIMENSION', missingDimensions: missingDimensions };
        }
    }

    if (shouldShowOutOfStock(props, true)) {
        const availableQuantity = (productAvailability && productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity, 0);

        return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
    }

    // When Custom price is selected, if there is no keyed-in price or keyed-in price is out of limit, should return error.
    if (
        isCustomPriceSelected &&
        (!customPriceAmount ||
            customPriceAmount > (maximumKeyInPrice || defaultMaximumKeyInPrice) ||
            customPriceAmount < (minimumKeyInPrice || defaultMinimumKeyInPrice))
    ) {
        return { failureReason: 'INVALIDCUSTOMAMOUNT' };
    }

    // Only allow adding to cart if not showing out of stock
    return undefined;
};

const shouldShowOutOfStock = (props: IAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
    if (
        props.context.app.config.enableStockCheck === undefined ||
        props.context.app.config.enableStockCheck === false ||
        props.isLoading ||
        props.isUpdatingDimension ||
        props.isAddServiceItemToCart
    ) {
        // Out of stock turn off, don't bother showing out of stock
        return false;
    }

    if (!props.data || !props.data.product.RecordId) {
        // No product exists, don't bother showing out of stock
        return false;
    }

    if (props.data.product?.Dimensions) {
        if (props.data.product.Dimensions?.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also don't show out of stock
            return false;
        }
    }
    const includedQuantityNumber = includeCurrentQuantity && props.quantity ? props.quantity : 1;

    return props.productAvailability &&
        props.productAvailability.AvailableQuantity !== undefined &&
        props.productAvailability.AvailableQuantity >= includedQuantityNumber
        ? false
        : true;
};

const isIntermediateState = (props: IAddToCartComponentProps): boolean => {
    if (props.data.product?.Dimensions) {
        if (props.data.product.Dimensions?.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also not in intermediate state
            /** VSI Customization - Start */
            return true;
            /** VSI Customization - End */
        }
    }

    if (!props.isLoading && !props.isUpdatingDimension) {
        return false;
    }

    return true;
};

const propagateResult = (props: IAddToCartComponentProps, result: ICartActionResult): void => {
    if (props.onAdd) {
        props.onAdd(result);
    }
};

const propagateError = (props: IAddToCartComponentProps, result: IAddToCartFailureResult): void => {
    if (props.onError) {
        props.onError(result);
    }
};

export const AddToCartComponent: React.FunctionComponent<IAddToCartComponentProps> = msdyn365Commerce.createComponentOverride<
    // @ts-ignore
    IAddtoCartComponent
>('AddToCart', { component: AddToCart, ...AddToCartComponentActions });

export default AddToCartComponent;
