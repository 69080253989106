import React from 'react';
import { Node } from '@msdyn365-commerce-modules/utilities';
import {
    IBuyboxKeyInPriceViewProps
} from '@msdyn365-commerce-modules/buybox';

export const BuyboxKeyInPrice = (props: IBuyboxKeyInPriceViewProps): JSX.Element => {
    const { ContainerProps, LabelContainerProps, heading, input } = props;

    return (
        <Node {...ContainerProps}>
            <Node {...LabelContainerProps}>{heading}</Node>
            {input}
        </Node>
    );
};
