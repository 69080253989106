import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { IMFIAvailablePromotionDescription, IMFIItem } from '../actions/EyebrowMessageDataServiceEntities.g';
import getEyebrowAction, { GetEyebrowMessageInput } from '../actions/get-eyebrow-message.action';

const _getEyebrowMessage = async (products: IMFIItem[], context: ICoreContext<IGeneric<IAny>>, isCached?: boolean, pageType?: string) => {
    const priceGroupForAPICall = context.app && context.app.config.priceGroup;
    if (products?.length > 0 && priceGroupForAPICall) {
        const items: IMFIItem[] | undefined = [];
        products.map((item) => {
            const singleItem: IMFIItem = {
                ItemId: item.ItemId,
                DistinctProductVariant: item.DistinctProductVariant
            };
            items.push(singleItem);
        });

        const requestBody: IMFIAvailablePromotionDescription = {
            PriceGroup: priceGroupForAPICall,
            Items: items
        };
        const getEyebrowMessageInput = new GetEyebrowMessageInput(requestBody, pageType, isCached);
        try {
            const result = await getEyebrowAction(getEyebrowMessageInput, context.actionContext);
            if (result) {
                return result;
            }
        } catch (error) {
            console.log('Error', error);
        }
    }
    return null;
};

export default _getEyebrowMessage;
