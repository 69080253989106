import React from 'react';
import { Node } from '@msdyn365-commerce-modules/utilities';
import {
    IBuyboxShopSimilarLookViewProps
} from '@msdyn365-commerce-modules/buybox';

export const BuyboxShopSimilarItems = (props: IBuyboxShopSimilarLookViewProps): JSX.Element => {
    const { ContainerProps, errors, input } = props;

    return (
        <Node {...ContainerProps}>
            {errors}
            {input}
        </Node>
    );
};