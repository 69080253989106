import React, {useState, useEffect, useRef} from "react";
import _getViewPort from "../../Utilities/get-view-port";
import MsDyn365, { ICoreContext, IModule } from '@msdyn365-commerce/core';
import { addThrottledEvent } from '@msdyn365-commerce-modules/utilities';

const updateViewPort = (context: ICoreContext): boolean => {
    const viewport = _getViewPort(context);
    if (viewport === 'lg' || viewport === 'xl') {
        return true;
    } else {
        return false;
    }
};


export const useIsDesktop = (context: ICoreContext): boolean => {
    const [isDesktop, setIsDesktop] = useState(false);
    const resizeThrottledEventHandler = useRef<(this: Window, ev: UIEvent) => any>(() => {});
    useEffect(()=>{
        if (MsDyn365.isBrowser) {
            setIsDesktop(updateViewPort(context));
            resizeThrottledEventHandler.current = addThrottledEvent(window, 'resize', () => setIsDesktop(updateViewPort(context)));
        }
        return ()=>{
            MsDyn365.isBrowser && window.removeEventListener('resize', resizeThrottledEventHandler.current, false);
        };
    },[]);
    return isDesktop;
};

export interface IWithIsDesktopProps {
    isDesktop: boolean;
}

export const withIsDesktop = <P extends IModule>(Component: React.ComponentType<P>) => {

    return (props:P) => {
        const isDesktop = useIsDesktop(props.context);
        return <Component {...props} isDesktop={isDesktop} />;
    };
};
