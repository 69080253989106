import React from 'react';
import {
    getTimeZoneAbbr,
    secondsToTime
} from '../../../mfrm-store-selector/components/store-selector-location-line-item/date-time-helpers';
import { Node } from '@msdyn365-commerce-modules/utilities';
import { BuyboxStoreHoursProps } from './types';
export const BuyboxStoreHours = ({
    location,
    storeHoursClosedText,
    storeCloseAtHours,
    storeOpenAtHours
}: BuyboxStoreHoursProps): JSX.Element | null => {
    const isClosedToday = location.ExtensionProperties?.find(x => x.Key === 'Yext_Today_IsClosed')?.Value?.BooleanValue;
    const isClosed = location.ExtensionProperties?.find(x => x.Key === 'Yext_Is_Store_Closed')?.Value?.BooleanValue;
    const startTime = location.ExtensionProperties?.find(x => x.Key === 'Yext_Today_OpenFrom')?.Value?.IntegerValue;
    const endTime = location.ExtensionProperties?.find(x => x.Key === 'Yext_Today_OpenTo')?.Value?.IntegerValue;
    const timeZone = location.ExtensionProperties?.find(x => x.Key === 'Channel_TimeZone_InfoId')?.Value?.StringValue;
    const timeZoneAbbr = getTimeZoneAbbr(timeZone);
    if (isClosedToday && isClosedToday !== null) {
        return (
            <Node className='open-until-text' tag='p'>
                Today: {storeHoursClosedText}
            </Node>
        );
    } else if (startTime !== null || endTime !== null) {
        return (
            <Node className='open-until-text' tag='p'>
                Today:{' '}
                {!isClosed
                    ? `${storeCloseAtHours} ${secondsToTime(endTime)} ${timeZoneAbbr}`
                    : `${storeOpenAtHours} ${secondsToTime(startTime)} ${timeZoneAbbr}`}
            </Node>
        );
    } else {
        return null;
    }
};

BuyboxStoreHours.displayName = 'BuyboxStoreHours';
