import React from 'react';
import { BVRatingSummary } from '../../../../Utilities/components/BVRatingSummary';
import MsDyn365, { ICoreContext } from '@msdyn365-commerce/core';
import { getBVAttributeValues } from '../../../../Utilities/get-bv-attributes';
import { RatingComponent } from '@msdyn365-commerce/components';
import { format } from '@msdyn365-commerce-modules/utilities';
import { Node } from '@msdyn365-commerce-modules/utilities';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { IMfrmBuyboxResources } from '../../mfrm-buybox.props.autogenerated';

interface Props {
    hideRating: boolean;
    isBazaarVoice: boolean | undefined;
    itemId: string | undefined;
    variantId: number | undefined;
    id: string;
    typeName: string;
    context: ICoreContext<{
        [x: string]: any;
    }>;
    productSpecification: AttributeValue[] | undefined;
    resources: IMfrmBuyboxResources;
}

const _scrollToBVHosted = () => {
    if (MsDyn365.isBrowser) {
        const ratingSection = document?.getElementById('writeFirstReview');
        const isRatingReviewsLoaded = ratingSection?.getElementsByTagName('div')[0]?.getAttribute('data-bv-ready');
        isRatingReviewsLoaded && ratingSection?.scrollIntoView({ behavior: 'smooth' });
    }
};

export const BuyboxProductRating = ({
    hideRating,
    isBazaarVoice,
    itemId,
    variantId,
    id,
    typeName,
    context,
    productSpecification,
    resources
}: Props) => {
    if (hideRating) {
        return null;
    }
    if (isBazaarVoice) {
        return <BVRatingSummary itemId={itemId} onClick={_scrollToBVHosted} variantId={variantId} />;
    }
    if (!productSpecification) {
        return null;
    }

    const averageRating = getBVAttributeValues(productSpecification, 'BVAverageOverallRating');
    const reviewsCount = getBVAttributeValues(productSpecification, 'BVTotalReviewCount');
    return (
        <Node className='ms-buybox__review-link' onClick={_scrollToBVHosted}>
            <RatingComponent
                avgRating={averageRating}
                readOnly={true}
                ariaLabel={format(resources.averageRatingAriaLabel, averageRating, '5')}
                ratingCount={`${reviewsCount}`}
                data={{}}
                context={context}
                id={id}
                typeName={typeName}
            />
            {!(productSpecification && (averageRating || reviewsCount)) && (
                <a role='button' href='javascript:void(0)'>
                    Write the first review
                </a>
            )}
        </Node>
    );
};
