import React from 'react';
import { Node } from '@msdyn365-commerce-modules/utilities';
import { BuyboxStoreHours } from '../buybox-store-hours/buybox-store-hours';
import { BuyboxStoreDetailsProps } from './types';

export const BuyboxStoreDetails = ({
    isSociEnabled,
    sociPrefferedStore,
    storeAddressLine1,
    storeAddressLine2,
    phoneNumber,
    preferredStore,
    storeHoursClosedText,
    storeCloseAtHours,
    storeOpenAtHours,
    sociStoreLocatorUrl,
    storeLocatorUrl,
    onClickNameOrDetails,
    viewStoreDetailsText,
    onClickChangeStore,
    changeStoreText
}: BuyboxStoreDetailsProps) => {
    return (
        <Node className='ms-buybox__try-in-store--v2__open-store-details'>
            {isSociEnabled && sociPrefferedStore ? (
                <Node className='address-lines'>
                    <Node className='address-line1' tag='p'>
                        {storeAddressLine1}
                    </Node>
                    <Node className='address-line2' tag='p'>
                        {storeAddressLine2}
                    </Node>
                    {sociPrefferedStore && (
                        <Node className='address-line2'>{`${sociPrefferedStore.city}, ${sociPrefferedStore.state} ${sociPrefferedStore.postalcode}`}</Node>
                    )}
                </Node>
            ) : (
                <Node className='address-lines'>
                    <Node className='address-line1' tag='p'>
                        {storeAddressLine1}
                    </Node>
                    <Node className='address-line2' tag='p'>
                        {storeAddressLine2}
                    </Node>
                </Node>
            )}
            {phoneNumber && (
                <a className='store-contact-text' title={phoneNumber} href={`tel:${phoneNumber}`} aria-label={phoneNumber}>
                    {phoneNumber}
                </a>
            )}
            {!isSociEnabled && (
                <BuyboxStoreHours
                    location={preferredStore}
                    storeHoursClosedText={storeHoursClosedText}
                    storeCloseAtHours={storeCloseAtHours}
                    storeOpenAtHours={storeOpenAtHours}
                />
            )}
            <Node className='ms-buybox__try-in-store--v2__open-store-details-CTA'>
                <Node
                    tag='a'
                    role='button'
                    className='btn-store-details'
                    href={isSociEnabled ? sociStoreLocatorUrl : storeLocatorUrl}
                    onClick={(event: MouseEvent) => onClickNameOrDetails(event)}
                    target='_blank'
                >
                    {viewStoreDetailsText}
                </Node>
                <Node tag='a' role='button' className='btn-change-store' onClick={onClickChangeStore} href='javascript:void(0)'>
                    {changeStoreText}
                </Node>
            </Node>
        </Node>
    );
};

BuyboxStoreDetails.displayName = 'BuyboxStoreDetails';
