import { useRef, useEffect } from 'react';

interface Props {
  onClickOutside: (event: MouseEvent) => void;
}

const useClickOutside = (props: Props) => {
  const ref = useRef<HTMLElement>(null);

  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      props.onClickOutside(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return ref;
};

export default useClickOutside;
