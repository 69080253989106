import { getCategoryPathsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { IActionContext } from '@msdyn365-commerce/core-internal';
export const getUtagProductCategory = async (actionContext: IActionContext, channelId: number, catalogId: number = 0, RecordId: number) => {
    //context, channelId, catalogId, categoryPathLookups
    const categoryPathResults = await getCategoryPathsAsync(
        { callerContext: actionContext, queryResultSettings: {} },
        channelId,
        catalogId,
        [{ ProductId: RecordId }]
    );
    const categoryPath = categoryPathResults[0]?.CategoryPath?.[0] && categoryPathResults[0].CategoryPath[0];
    if (!categoryPath?.Segments?.length) {
        return [];
    }
    return [categoryPath.Segments[categoryPath.Segments.length - 1].CategoryName!];
};
