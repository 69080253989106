import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import getAllProductVariants, { BuyboxGetDimensionVariantAvailabilityInput } from '../data-actions/buybox-get-all-variants-list';
import { IActionContext } from '@msdyn365-commerce/core-internal';

export const getUtagVariantId = async (
    MasterProductId: number,
    channelId: number,
    actionContext: IActionContext,
    RecordId: number,
    ItemId: string
) => {
    if (MasterProductId && MasterProductId > 0) {
        const input = new BuyboxGetDimensionVariantAvailabilityInput(MasterProductId, channelId);
        const variantsResp: SimpleProduct[] = await getAllProductVariants(input, actionContext);
        for (let variantIter = 0; variantIter < variantsResp.length; variantIter++) {
            if (variantsResp[variantIter].RecordId === RecordId) {
                const ePropsVariant = variantsResp[variantIter].ExtensionProperties!;
                if (ePropsVariant?.length > 0) {
                    for (let eIter = 0; eIter < ePropsVariant?.length; eIter++) {
                        if (ePropsVariant[eIter].Key === 'RetailVariantId') {
                            return [ePropsVariant[eIter].Value?.StringValue];
                        }
                    }
                }
            }
        }
    }
    const variantofId = `V000${ItemId}`;
    return [variantofId?.slice(0, -1)];
};

export const getUtagRetailVariantIdAndRecId = async (
    MasterProductId: number,
    channelId: number,
    actionContext: IActionContext,
    RecordId: number,
    ItemId: string
) => {
    if (MasterProductId && MasterProductId > 0) {
        const input = new BuyboxGetDimensionVariantAvailabilityInput(MasterProductId, channelId);
        const variantsResp: SimpleProduct[] = await getAllProductVariants(input, actionContext);
        for (let variantIter = 0; variantIter < variantsResp.length; variantIter++) {
            if (variantsResp[variantIter].RecordId === RecordId) {
                const ePropsVariant = variantsResp[variantIter].ExtensionProperties!;
                if (ePropsVariant?.length > 0) {
                    for (let eIter = 0; eIter < ePropsVariant?.length; eIter++) {
                        if (ePropsVariant[eIter].Key === 'RetailVariantId') {
                            return { 'retailVariantId': ePropsVariant[eIter].Value?.StringValue, 'recordId': RecordId };
                        }
                    }
                }
            }
        }
    }
    const variantofId = `V000${ItemId}`;
    return { 'retailVariantId': variantofId?.slice(0, -1), 'recordId': RecordId };
};
