import {
    getBuyboxProductDescription,
    getBuyboxShopSimilarButton,
    IErrorState,
    ShopSimiliarButtonType
} from '@msdyn365-commerce-modules/buybox';
import {
    FinitePromiseQueue,
    FinitePromiseQueueError,
    getDeliveryOptionsForSelectedVariant,
    GetDeliveryOptionsForSelectedVariantInput,
    getPriceForSelectedVariant,
    getProductPageUrlSync,
    getProductAvailabilitiesForSelectedVariant,
    IPromiseQueue,
    PriceForSelectedVariantInput,
    ProductAvailabilitiesForSelectedVariantInput
} from '@msdyn365-commerce-modules/retail-actions';
import { IBuyboxGetDimensionVariantAvailabilityActionData } from '../../data-actions/buybox-get-dimension-variants-availability-action';
import { getTelemetryObject, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import MsDyn365, { getUrlSync, ICoreContext } from '@msdyn365-commerce/core';

import {
    ProductDimensionValue,
    ProductPrice,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy';
import { getAttributeValuesAsync, getDimensionValuesAsync, getVariantsByDimensionValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { AttributeValue, OrgUnitLocation } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import classnames from 'classnames';
import dayjs from 'dayjs';
import * as React from 'react';
import Cookies from 'universal-cookie';
import _getViewPort from '../../Utilities/get-view-port';
import {
    IMFICartLine,
    IMFICLDeliveryScheduleParameters
} from '../../actions/CoreProductDataServiceEntities.g';
import { IMFIItem, IMFIPromotion } from '../../actions/EyebrowMessageDataServiceEntities.g';
import getTryInStoreAction, { GetTryInStoreInfoInput } from '../../actions/get-try-in-store-info.action';
import { IMFITryInStore } from '../../actions/TryInStoreDataServiceEntities.g';
import getAllProductVariants, { BuyboxGetDimensionVariantAvailabilityInput } from '../../data-actions/buybox-get-all-variants-list';
import { IBuyboxGetDimensionPricingActionData } from '../../data-actions/buybox-get-dimension-pricing-action';
import { fireAnalyticsLink } from '../../Utilities/analytics/clickTrack';
import _getDeliveryByMessage from '../../Utilities/get-delivery-by-message';
import _getEyebrowMessage from '../../Utilities/get-eyebrow-message';
import _getInventoryQuantity from '../../Utilities/get-inventory-quantity';
import { _coreProductATPCallForInventoryStatusForAllVariants, _getActivePrices } from '../../Utilities/mfrm-buybox-utils';
import { _getSoonestDeliveryDate } from '../../Utilities/product-search-result-utils';
import {
    getBuyboxAddToCart,
    getBuyBoxInventoryLabel,
    getBuyboxKeyInPrice,
    getBuyboxProductPrice,
    getBuyboxProductTitle,
    getQuantityLimitsMessages
} from './common/buybox-components';
import { getBuyboxFindInStore } from './components/mfrm-buybox-find-in-store';
import { IMfrmBuyboxProps as IBuyboxModuleProps, IMfrmBuyboxResources } from './mfrm-buybox.props.autogenerated';
import { IVariantStockInfo } from './mfrm-buybox.view';
import { parseBrandName } from '../../Utilities/get-product-brand';
import { variantQueryStringName } from '../../Utilities/constants';
import { getZipCode } from '../../Utilities/get-zipcode';
import { withIsDesktop } from '../../common/hooks/useIsDesktop';
import {
    IBuyboxModulePropsExtended,
    IBuyboxCallbacksLite,
    IBuyboxStateExtended,
    IBuyboxViewPropsExtended,
    ISimpleProductExtended
} from './types';
import { IMfrmBuyboxData } from './mfrm-buybox.data';
import { BuyboxShopSimilarItems } from './components/buybox-shop-similar-items';
import { BuyboxKeyInPrice } from './components/buybox-key-in-price';
import { BuyboxCustomerSatisfactionScore } from './components/buybox-customer-satisfaction-score/buybox-customer-satisfaction-score';
import { getProductSpecNumber } from '../../Utilities/getProductSpecificationDataAttributeValue';
import { BuyboxTryInStoreRedesign } from './components/buybox-try-in-store-redesign/buybox-try-in-store-redesign';
import { BuyboxProductRating } from './components/buybox-product-rating/buybox-product-rating';
import { _getProductBadges } from '../../Utilities/product-badge-utils';

export const getProductURLForUtag = (product: SimpleProduct | undefined, context: ICoreContext): string => {
    const productUrl = getProductPageUrlSync(product?.Name || '', product?.RecordId || 0, context?.actionContext, undefined);
    return `${context.request.url.requestUrl.origin}${productUrl}?variantid=${product?.RecordId}`;
};

export const getProductSizeForUTag = (product: SimpleProduct | undefined): string | undefined => {
    //TODO remove magic numbers like 3 and use constant like DIMENSION_SIZE_TYPE
    return product?.Dimensions?.find(item => item.DimensionTypeValue === 3)?.DimensionValue?.Value?.toLowerCase();
};

/**
 * Buybox Module
 */
class Buybox extends React.PureComponent<IBuyboxModulePropsExtended, IBuyboxStateExtended> {
    /**
     * A queue of tasks of processing the changes in the dimensions.
     * Limit to two processes:
     * 1 - for the current process, which is under execution at the moment.
     * 2 - next process, which will process the latest version of data.
     * @remark Enqueueing new promises will discard the previous ones (except the one which is under processing).
     */
    //Constructor
    constructor(props: IBuyboxModulePropsExtended) {
        super(props);
        this.state = {
            errorState: {
                configureErrors: {}
            },
            quantity: 1,
            min: undefined,
            max: undefined,
            selectedProduct: undefined,
            productPrice: undefined,
            productDeliveryOptions: undefined,
            modalOpen: false,
            isUpdatingDimension: false,
            isUpdatingDeliveryOptions: false,
            smallParcelATCallMade: false,
            StatusATPCall: false,
            ATPCall: false,
            recordId: this.props.data.product.result?.RecordId,
            itemId: this.props.data.product.result?.ItemId,
            masterProductId: this.props.data.product.result?.MasterProductId,
            extensionProps: [],
            eyebrowMessageResultState: null,
            deliveryMessage: '',
            deliveryMessageATPResultState: null,
            inventoryStatusATPResultState: null,
            successfulATC: false,
            currentProduct: undefined,
            currentProductDimensions: undefined,
            variantAvailibilityList: [],
            variantAvailibilityUpdating: false,
            batchedAtpInventoryStatusData: null,
            variantIdForTracking: '',
            ecomAllVairantStockInfoConcatenated: [],
            showViewCartLink: false,
            isStoreDetailsOpen: false,
            zipCode: '',
            isEyeBrowLoading: true,
            isStoreLoading: true,
            preferredStore: {},
            tealiumRecordIdFor: ''
        };
        this.telemetryContent = getTelemetryObject(props.context.request.telemetryPageName!, props.friendlyName, props.telemetry);
        this.updateSuccessfulATC = this.updateSuccessfulATC.bind(this);
        this._setAllProductVariantsResult = this._setAllProductVariantsResult.bind(this);
        this.timer = null;
        this._updateUtagDataOnInitialLoad().catch(e => {
            throw e;
        });
    }
    //React Lifecycle methods
    public async componentDidMount(): Promise<void> {
        const {
            data: {
                product: { result: product },
                productPrice,
                cart: { result: cart }
            },
            context: {
                request: { }
            }
        } = this.props;
        // set availability list for all variants
        if (this.props.data.productSpecificationData.result?.length) {
            this.props.data.allVariants.result &&
                this._updateAvailbilityForVariantsUpdated(
                    this.props.data.allVariants.result,
                    this.props,
                    this.props.data.productSpecificationData.result
                );
        }
        this._updatePrice(await productPrice);
        if (product) {
            // check if the product is service or not by product type
            const PRODUCTASSERVICE = 2;

            if (product.ItemTypeValue === PRODUCTASSERVICE) {
                this.setState({ isServiceItem: true });
            }
            await this._updateQuantitiesInState(product);
            // VSI - Customization Start 48959
            if (product.MasterProductId) {
                await this._eyeBrowMessageAsyncFunc(true);
            }
            // VSI - Customization End 48959
            const canTryInStores = this._getProductSpecificationAttributeByKeyName('try in store');
            !canTryInStores && this.setState({ isStoreLoading: false });
            canTryInStores && (await this._tryInStoreAsyncCall());
        }
        if (
            this.props.data.product.result?.RecordId !== this.state.recordId ||
            this.props.data.product.result?.ItemId !== this.state.itemId
        ) {
            this.setState({
                recordId: this.props.data.product.result!.RecordId,
                masterProductId: this.props.data.product.result!.MasterProductId,
                itemId: this.props.data.product.result!.ItemId
            });
        }
        if (this.state.recordId && this.state.masterProductId && this.state.itemId) {
            this._setRecentlyViewProducts(this.state.recordId, this.state.masterProductId);
        }
        const newCartTotalItems = cart && cart.totalItemsInCart ? cart.totalItemsInCart : 0;
        if (newCartTotalItems && newCartTotalItems > 0) {
            this.setState({
                showViewCartLink: true
            });
        }
        else {
            this.setState({
                showViewCartLink: false
            });

        }
        const zipCode = getZipCode(this.props.context.actionContext);
        zipCode && this.setState({ zipCode: zipCode });

        const isSociEnabled = this.props.app.config.enableSociLocations || false;
        const preferredStore = isSociEnabled ? this._getSociPreferredStore() : this._getPreferredStore();
        (!preferredStore || Object.keys(preferredStore).length === 0) && this.setState({ isStoreLoading: false });
        preferredStore && this.setState({ preferredStore: preferredStore });
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public async componentDidUpdate(prevProps: IBuyboxModuleProps<IMfrmBuyboxData>, prevState: IBuyboxStateExtended): Promise<void> {
        const {
            data: {
                product: { result: product },
                cart: { result: cart }
            }
        } = this.props;
        prevState.selectedProduct?.then(selProd => {
            this.state.selectedProduct?.then(async currSelProd => {
                if (selProd?.RecordId !== currSelProd?.RecordId) {
                    this.setState({ smallParcelATCallMade: false });
                }
            });
        });

        if (
            this.props.data.product.result?.RecordId !== this.state.recordId ||
            this.props.data.product.result?.ItemId !== this.state.itemId
        ) {
            this.setState(
                {
                    currentProduct: this.props.data.product.result,
                    recordId: this.props.data.product.result!.RecordId,
                    masterProductId: this.props.data.product.result!.MasterProductId,
                    itemId: this.props.data.product.result!.ItemId,
                    StatusATPCall: false,
                    ATPCall: false
                },
                async () => {
                    this.state.recordId && this._setRecentlyViewProducts(this.state.recordId, this.state.masterProductId);
                    // VSI - Customization Start 48959
                    const allVariants = this.props?.data?.allVariants?.result ? this.props?.data?.allVariants?.result : [];
                    await this.setDefaultOOSVariant(allVariants);
                    await this._eyeBrowMessageAsyncFunc(true);
                    // VSI - Customization End 48959
                }
            );
        }
        const canTryInStores = this._getProductSpecificationAttributeByKeyName('try in store');
        const isSociEnabled = this.props.app.config.enableSociLocations || false;
        if (canTryInStores) {
            if (isSociEnabled) {
                await this._tryInStoreAsyncCall();
            } else {
                const preferredStore = this._getPreferredStore();
                if (preferredStore?.OrgUnitNumber && this.state.OrgUnitNumber && this.state.OrgUnitNumber !== preferredStore.OrgUnitNumber) {
                    await this._tryInStoreAsyncCall();
                }
            }
        }

        if (this.state.extensionProps.length <= 0 && this.props.data.product.result) {
            if (this.props.data.product?.result?.ExtensionProperties && this.props.data.product?.result?.ExtensionProperties.length >= 1) {
                this.setState({ extensionProps: this.props.data.product.result.ExtensionProperties });
            }
        }
        const newCartTotalItems = cart && cart.totalItemsInCart ? cart.totalItemsInCart : 0;
        if (newCartTotalItems && newCartTotalItems > 0) {
            this.setState({
                showViewCartLink: true
            });
        }
        else {
            this.setState({
                showViewCartLink: false
            });
        }

        if (this.state.tealiumRecordIdFor === '' || (this.props.data.product.result?.RecordId !== this.state.recordId)) {
            for (const element of this.state.ecomAllVairantStockInfoConcatenated) {
                const [recordId, isInStock] = element && element.split('|');
                if (product?.RecordId.toString() === recordId && isInStock === '0') {
                    // product found that is in stock then no need to loop through other values
                    await this._sendProductDataToTealium(this.props, product);
                    break;
                } else if (product?.RecordId.toString() === recordId && isInStock === '1') {
                    break;
                }
            }
        }
        const zipCode = getZipCode(this.props.context.actionContext);
        zipCode && zipCode !== this.state.zipCode && this.setState({ zipCode: zipCode });

        if (this.state.zipCode !== prevState.zipCode) {
            this.props.data.allVariants.result && this.props.data.productSpecificationData.result?.length &&
                this._updateAvailbilityForVariantsUpdated(
                    this.props.data.allVariants.result,
                    this.props,
                    this.props.data.productSpecificationData.result
                );
        }
    }

    public async componentWillUnmount(): Promise<void> {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    //Public methods
    public updateSuccessfulATC = (): void => {
        this.setState({ successfulATC: true });
        this.timer = setTimeout(() => {
            this.setState({ successfulATC: false });
        }, 4500);
    };

    public extendAllProductVariants(
        allProductVariants: SimpleProduct[] | undefined,
        prices: IBuyboxGetDimensionPricingActionData[]
    ): ISimpleProductExtended[] | undefined {
        let extendedAllProductVariants: ISimpleProductExtended[] | undefined =
            allProductVariants &&
            allProductVariants?.map(variant => {
                let extendedObj: ISimpleProductExtended = { ...variant };
                variant.Dimensions?.forEach(dimension => {
                    if (dimension.DimensionTypeValue === 3) {
                        extendedObj = { productSizeName: dimension.DimensionValue?.Value, ...variant };
                    }
                });
                return extendedObj;
            });
        extendedAllProductVariants =
            extendedAllProductVariants &&
            extendedAllProductVariants.map(variant => {
                let extendedObj: ISimpleProductExtended = { ...variant };
                prices?.forEach(dimensionPrice => {
                    if (dimensionPrice?.discountAmount && dimensionPrice?.name === variant?.productSizeName) {
                        const priceAfterDiscount = Math.round(dimensionPrice?.price - dimensionPrice?.discountAmount);
                        extendedObj = {
                            discount: dimensionPrice?.discountAmount,
                            price: dimensionPrice?.price,
                            priceAfterDiscount: priceAfterDiscount,
                            ...variant
                        };
                    }
                });
                return extendedObj;
            });
        return extendedAllProductVariants;
    }

    //Public async methods
    public async getNextInventoryProduct(
        product: SimpleProduct | undefined,
        productSpecificationData: AttributeValue[] | undefined,
        allProductVariants: ISimpleProductExtended[] | undefined,
        context: ICoreContext<{
            [x: string]: any;
        }>,
        variantAvailibilityList: IBuyboxGetDimensionVariantAvailabilityActionData[]
    ): Promise<SimpleProduct | undefined> {
        const {
            app: {
                config: { smallParcelAttributeKey }
            }
        } = context;

        const smallParcelVariantsListObj = {};
        variantAvailibilityList?.forEach(obj => {
            const key = obj?.variantID;
            smallParcelVariantsListObj[key] = obj;
        });

        const shippingInformation = productSpecificationData
            ?.find(attr => attr.Name?.trim() === smallParcelAttributeKey)
            ?.TextValue?.toLowerCase();
        const productType = this._getProductType(shippingInformation).toLowerCase();
        let isProductFound = false;
        let inStockLowestPriceVariant;

        // to check that if current loaded variant is OOS or not
        for (const element of this.state.ecomAllVairantStockInfoConcatenated) {
            const [recordId, isInStock] = element && element.split('|');
            if (product?.RecordId.toString() === recordId && isInStock === '0') {
                // product found that is in stock then no need to loop through other values
                inStockLowestPriceVariant = product;
                isProductFound = true;
                break;
            } else if (product?.RecordId.toString() === recordId && isInStock === '1') {
                break;
            }
        }

        // if current loaded variant is OOS then check the others
        !isProductFound &&
            allProductVariants?.filter(variantObj => {
                if (!isProductFound) {
                    for (const element of this.state.ecomAllVairantStockInfoConcatenated) {
                        const [recordId, isInStock] = element && element.split('|');
                        if (variantObj?.RecordId.toString() === recordId && isInStock === '0') {
                            // product found that is in stock then no need to loop through other values
                            const smallParcelProduct = smallParcelVariantsListObj[variantObj?.RecordId];
                            if (productType === 'small parcel') {
                                if (smallParcelProduct?.isAvailable === true &&
                                    variantObj?.RecordId === smallParcelProduct?.variantID) {
                                    inStockLowestPriceVariant = variantObj;
                                    isProductFound = true;
                                    break;
                                }
                            } else {
                                inStockLowestPriceVariant = variantObj;
                                isProductFound = true;
                                break;
                            }
                        } else if (variantObj.RecordId.toString() === recordId) {
                            // isInStock is 0 but record id is matched then no need to loop through other values
                            break;
                        }
                    }
                }
            });
        return inStockLowestPriceVariant;
    }

    public async makeCoreProductDeliveryMessage(str: string): Promise<void> {
        const {
            data: {
                productSpecificationData: { result: productSpecificationData }
            },
            context: {
                app: {
                    config: { smallParcelAttributeKey }
                }
            }
        } = this.props;
        const outOfStock = productSpecificationData?.find(
            attr => attr.Name?.trim().toLowerCase() === this.props.context.app.config.outOfStockProductKey?.trim().toLowerCase()
        )?.BooleanValue;
        const shippingInformation = productSpecificationData
            ?.find(attr => attr.Name?.trim() === smallParcelAttributeKey)
            ?.TextValue?.toLowerCase();

        if (this._getProductType(shippingInformation).toLowerCase() === 'core' && !this.state.ATPCall && !outOfStock) {
            this.setState({ ATPCall: true });
            await this.getDeliverySlotFromATP(true);
        } else if (this._getProductType(shippingInformation).toLowerCase() === 'small parcel' && !outOfStock) {
            this.setState({ StatusATPCall: true });
            // await this.getInventoryStatusCall(true);
            const inventoryStatusData = this.state.batchedAtpInventoryStatusData?.ATPInventoryStatusData
                ? this.state.batchedAtpInventoryStatusData?.ATPInventoryStatusData
                : null;
            let selectedObj = inventoryStatusData
                ? inventoryStatusData?.find(item => item.VariantRecordId?.toString() === this.state.currentProduct?.RecordId?.toString())
                : null;
            selectedObj = selectedObj ? { ...selectedObj, zipCode: inventoryStatusData![0]?.zipCode } : null;
            this.setState({ inventoryStatusATPResultState: selectedObj });
        }
    }

    public async getVariantID(updatedProduct?: SimpleProduct | undefined): Promise<string | undefined> {
        const {
            data: {
                product: { result: product }
            },
            context: { actionContext, request }
        } = this.props;
        const { RecordId, ItemId } = updatedProduct && updatedProduct !== undefined ? updatedProduct : product || {};
        let variantId: string | undefined;
        if (product?.MasterProductId && product?.MasterProductId > 0) {
            const input = new BuyboxGetDimensionVariantAvailabilityInput(product.MasterProductId, request.apiSettings.channelId);
            const variantsResp: SimpleProduct[] = await getAllProductVariants(input, actionContext);
            for (let variantIter = 0; variantIter < variantsResp.length; variantIter++) {
                if (variantsResp[variantIter].RecordId === RecordId) {
                    const ePropsVariant = variantsResp[variantIter].ExtensionProperties!;
                    if (ePropsVariant?.length > 0) {
                        for (let eIter = 0; eIter < ePropsVariant?.length; eIter++) {
                            if (ePropsVariant[eIter].Key === 'RetailVariantId') {
                                variantId = ePropsVariant[eIter].Value?.StringValue;
                                break;
                            }
                        }
                    }
                }
            }
        } else {
            const variantofId = `V000${ItemId}`;
            variantId = variantofId?.slice(0, -1);
        }

        return variantId;
    }

    //Private methods
    private dimensionUpdateQueue: IPromiseQueue<void> = new FinitePromiseQueue<void>(2);
    private dimensions: { [id: number]: string } = {};
    private telemetryContent: ITelemetryContent;
    private timer: NodeJS.Timeout | null;

    private buyboxCallbacks: IBuyboxCallbacksLite = {
        updateQuantity: (newQuantity: number): boolean => {
            const errorState = { ...this.state.errorState };
            errorState.quantityError = undefined;
            errorState.otherError = undefined;
            if (this.state.quantity !== newQuantity) {
                fireAnalyticsLink({
                    eventName: 'PDP-card-action',
                    eventCategory: 'PDP Card Action',
                    eventAction: 'Change Quantity',
                    eventLabel: 'Change Quantity PDP',
                    eventNoninteraction: 'false',
                    generalConfig: { enhanced_action: 'change_quantity' }
                });
            }

            this.setState({ quantity: newQuantity, errorState: errorState });
            return true;
        },
        updateErrorState: (newErrorState: IErrorState): void => {
            this.setState({ errorState: newErrorState });
        },
        dimensionSelectedAsync: (selectedDimensionId: number, selectedDimensionValueId: string): Promise<void> => {
            this.dimensions[selectedDimensionId] = selectedDimensionValueId;
            return this.dimensionUpdateQueue
                .enqueue(() => {
                    return this._updateDimensions();
                })
                .catch((reason: any) => {
                    // tslint:disable-line:no-any
                    // Ignore discarded processes.
                    if (reason !== FinitePromiseQueueError.ProcessWasDiscardedFromTheQueue) {
                        throw reason;
                    }
                });
        },
        getDropdownName: (dimensionType: number, resources: IMfrmBuyboxResources): string => {
            return this._getDropdownName(dimensionType, resources);
        },
        changeModalOpen: (isModalOpen: boolean): void => {
            this.setState({ modalOpen: isModalOpen });
        },
        changeUpdatingDimension: (isUpdatingDimension: boolean): void => {
            this.setState({ isUpdatingDimension: isUpdatingDimension });
        },
        /**
         * Update isUpdatingDeliveryOptions state.
         *
         * @param isUpdatingDeliveryOptions - The status of updating delivery options.
         */
        changeUpdatingDeliveryOptions: (isUpdatingDeliveryOptions: boolean): void => {
            this.setState({ isUpdatingDeliveryOptions });
        },
        updateKeyInPrice: (customPrice: number): void => {
            // Remove custom amount error when updating the custom price
            const errorState = { ...this.state.errorState };
            errorState.customAmountError = undefined;

            this.setState({ isPriceKeyedIn: true, keyInPriceAmount: customPrice, errorState: errorState });
            this._updatePrice(this.state.productPrice, customPrice);
        }
    };

    private _updateURLWithoutReloading(product: SimpleProduct | undefined) {
        if (this.props.context.app.config.queryBasedVariantSelection) {
            try {
                if (MsDyn365.isBrowser) {
                    const queryParamValue = variantQueryStringName;
                    const masterId = product?.MasterProductId;
                    const currUrl = window.location.href;
                    const urlWithIgnoredCase = new URL(currUrl.toString().toLocaleLowerCase());
                    if (masterId && currUrl.indexOf(`${masterId}.p`) !== -1) {
                        if (urlWithIgnoredCase.searchParams.has(queryParamValue)) {
                            if (urlWithIgnoredCase.searchParams.get(queryParamValue) === product?.RecordId?.toString()) {
                                return;
                            }
                        }
                        urlWithIgnoredCase.searchParams.set(queryParamValue, product?.RecordId.toString() || '');
                        window.history?.replaceState(window.history.state, '', urlWithIgnoredCase.toString()?.replace(urlWithIgnoredCase.host, window.location.host));
                    } else if (masterId && currUrl.indexOf(`${masterId}.p`) === -1) {
                        const productUrl = getProductPageUrlSync(
                            product?.Name || '',
                            masterId || 0,
                            this.props.context && this.props.context.actionContext,
                            undefined
                        );
                        if (urlWithIgnoredCase.searchParams.has(queryParamValue)) {
                            if (urlWithIgnoredCase.searchParams.get(queryParamValue) === product?.RecordId?.toString()) {
                                //pushstate only in this case, as here we need to update url with masterid
                                window.history.pushState({}, '', urlWithIgnoredCase.search ? `${productUrl}${urlWithIgnoredCase.search}` : productUrl);
                                return;
                            }
                        }
                        urlWithIgnoredCase.searchParams.set(queryParamValue, product?.RecordId.toString() || '');
                        window.history.pushState({}, '', urlWithIgnoredCase.search ? `${productUrl}${urlWithIgnoredCase.search}` : productUrl);
                    }
                }
            } catch (error) {
                console.log('Error [_updateURLWithoutReloading]', error);
            }
        } else {
            const currUrl = window.location.href;
            let queryParams = '';
            if (currUrl.indexOf('?') !== -1) {
                queryParams = currUrl.split('?')[1];
            }
            const productUrl = getProductPageUrlSync(
                product?.Name || '',
                product?.RecordId || 0,
                this.props.context && this.props.context.actionContext,
                undefined
            );
            if (MsDyn365.isBrowser) {
                window.history.pushState({}, '', queryParams ? `${productUrl}?${queryParams}` : productUrl);
            }
        }
    }

    private _updateQueryParamVariantId = (variantId: Number | undefined) => {
        if (MsDyn365.isBrowser) {
            const currUrl = window.location.href;
            const urlWithIgnoredCase = new URL(currUrl.toString().toLocaleLowerCase());
            if (urlWithIgnoredCase.searchParams.has(variantQueryStringName)) {
                if (urlWithIgnoredCase.searchParams.get(variantQueryStringName) === variantId?.toString()) {
                    return;
                }
            }
            // url is containing variantId
            urlWithIgnoredCase.searchParams.set(variantQueryStringName, variantId?.toString() || '');
            window.history?.replaceState(window.history.state, '', urlWithIgnoredCase.toString()?.replace(urlWithIgnoredCase.host, window.location.host));
            // window.history.pushState({}, '', urlWithIgnoredCase.href);
        }
    };
    private _setAllProductVariantsResult(result: IBuyboxGetDimensionVariantAvailabilityActionData[]) {
        this.setState({ variantAvailibilityList: result });
    }

    /** Method for setting cookies for recently viewed products */
    private _setRecentlyViewProducts = (recordId: number, masterProductId: number | undefined): void => {
        const cookies = new Cookies();
        const date = new Date(); // Now
        date.setDate(date.getDate() + 30); // Set now + 30 days as the new date

        const product = {
            masterProductId: masterProductId,
            recordId: recordId,
            expiry: date
        };

        interface IRecentlyViewedProducts {
            masterProductId: number;
            recordId: number;
            expiry: number;
        }
        let inserted = false;

        let recentlyViewedProductsStored = cookies.get(`RecentlyViewedProducts`);
        if (masterProductId !== undefined) {
            if (recentlyViewedProductsStored === undefined || recentlyViewedProductsStored.length === 0) {
                const recentlyViewedProducts = [];
                recentlyViewedProducts.push(product);
                cookies.set(`RecentlyViewedProducts`, recentlyViewedProducts, { path: '/' });
            } else {
                recentlyViewedProductsStored?.map((item: IRecentlyViewedProducts, index: number) => {
                    if (item.masterProductId === masterProductId) {
                        recentlyViewedProductsStored?.splice(index, 1);
                        recentlyViewedProductsStored = [product]?.concat(recentlyViewedProductsStored);
                        cookies.set(`RecentlyViewedProducts`, recentlyViewedProductsStored, { path: '/' });
                        inserted = true;
                    }
                });

                if (!inserted) {
                    if (recentlyViewedProductsStored.length >= 6) {
                        recentlyViewedProductsStored?.splice(0, 1);
                        recentlyViewedProductsStored = [product]?.concat(recentlyViewedProductsStored);
                        cookies.set(`RecentlyViewedProducts`, recentlyViewedProductsStored, { path: '/' });
                    } else {
                        recentlyViewedProductsStored = [product]?.concat(recentlyViewedProductsStored);
                        cookies.set(`RecentlyViewedProducts`, recentlyViewedProductsStored, { path: '/' });
                    }
                    inserted = false;
                }
            }
        }
    };
    private _setSmallParcelATPCallMade = (atpCallMade: boolean) => {
        this.setState({ smallParcelATCallMade: atpCallMade });
    };
    private _setStatusATPCall = (atpCallMade: boolean) => {
        this.setState({ StatusATPCall: atpCallMade });
    };
    private _setZipCode = (zipCode: string) => {
        this.setState({ zipCode: zipCode });
    };
    private _setATPCall = (atpCallMade: boolean) => {
        this.setState({ ATPCall: atpCallMade });
    };

    private _updatePrice(newPrice: ProductPrice | undefined, customPrice: number | undefined = this.state.keyInPriceAmount): void {
        if (this.state.isCustomPriceSelected && newPrice) {
            newPrice.CustomerContextualPrice = customPrice;
        }
        this.setState({ productPrice: newPrice });
        this._updateUtagData(this.props.data?.product?.result, newPrice?.CustomerContextualPrice).catch(e => {
            throw e;
        });
    }

    private _updateDimensionValue = (
        productDimensionFull: ProductDimensionFull,
        newValueId: string | undefined
    ): ProductDimensionValue | undefined => {
        if (newValueId && productDimensionFull.DimensionValues) {
            return productDimensionFull.DimensionValues.find(dimension => dimension.RecordId === +newValueId);
        }

        return undefined;
    };

    private _getDropdownName = (dimensionType: number, resources: IMfrmBuyboxResources): string => {
        const isGiftCard = this.props.data.product.result?.IsGiftCard;

        switch (dimensionType) {
            case 1: // ProductDimensionType.Color
                return resources.productDimensionTypeColor;
            case 2: // ProductDimensionType.Configuration
                return resources.productDimensionTypeConfiguration;
            case 3: // ProductDimensionType.Size
                return resources.productDimensionTypeSize;
            case 4: // ProductDimensionType.Style
                return isGiftCard ? resources.productDimensionTypeAmount : resources.productDimensionTypeStyle;
            default:
                return '';
        }
    };

    private _getPreferredStore = (): OrgUnitLocation => {
        if (!MsDyn365.isBrowser || typeof localStorage === 'undefined') {
            return {};
        } else {
            const localStoragePreferredStore = localStorage.getItem('_mfrm__prefferedStore_');
            if (localStoragePreferredStore) {
                return JSON.parse(localStoragePreferredStore);
            } else {
                return {};
            }
        }
    };

    private _getSociPreferredStore = (): any => {
        let preferredStore = {};
        if (MsDyn365.isBrowser && localStorage.getItem('_mfrm__prefferedStore_')) {
            preferredStore = JSON.parse(localStorage.getItem('_mfrm__prefferedStore_') || '');
        } else {
            preferredStore = {};
        }
        return preferredStore;
    };

    private _getProductSpecificationAttributeByKeyName = (attrName: string): boolean | undefined => {
        return (
            this.props.data.productSpecificationData &&
            this.props.data.productSpecificationData.result?.find(attr => attr.Name?.trim().toLowerCase() === attrName.trim().toLowerCase())
                ?.BooleanValue
        );
    };

    private _getProductType = (shippingInformation: string | undefined): string => {
        if (shippingInformation && shippingInformation.toLowerCase() === 'delivery') {
            return 'Core';
        }
        if (shippingInformation && shippingInformation.toLowerCase() === 'parcel') {
            return 'Small Parcel';
        }
        if (shippingInformation && shippingInformation.toLowerCase() === 'drop ship') {
            return 'Drop Ship';
        }
        return '';
    };

    private _updateUtagDataOnInitialLoad = async () => {
        const productprice = await this.props.data.productPrice;
        const product = await this.props.data.product;
        const category = await this.props.data.categoryPaths;
        const allCategoriesArr = category;
        const categoryObj = allCategoriesArr && allCategoriesArr[allCategoriesArr?.length - 1];
        const categoryName: string | undefined = categoryObj ? `${categoryObj?.Name}` : undefined;
        const fallBackBrandAttribute = 'BRAND';
        const attribute = this.props.context.app.config.brandBackofficeAttributePdp || fallBackBrandAttribute;
        let productBrandName =
            (await this.props.data.productSpecificationData).find(
                currAttribute =>
                    currAttribute.Name && currAttribute.Name.toLowerCase().trim() === attribute.toLowerCase().trim()
            )?.TextValue;
        productBrandName = productBrandName ? `${productBrandName}` : undefined;
        const productSize = product?.Dimensions?.find(item => item.DimensionTypeValue === 3)?.DimensionValue?.Value?.toLowerCase();
        const item = await this.props.data.listPageStateBuyBox;
        item.itemId = product.ItemId;
        item.variantId = this._getVariantIDOnLoad();
        item.name = product.Name;
        item.categoryName = categoryName;
        item.productBrandName = productBrandName;
        item.price = productprice.CustomerContextualPrice ? productprice.CustomerContextualPrice.toString() : product.BasePrice.toString();
        item.productUrl = getProductURLForUtag(product, this.props.context);
        item.productImageUrl = `${product?.PrimaryImageUrl}`;
        item.productSize = productSize;
    };

    private _getVariantIDOnLoad = (): string | undefined => {
        const {
            data: {
                product: { result: product }
            }
        } = this.props;
        const { RecordId, ItemId } = product || {};
        let variantId: string | undefined;
        const variantsResp: SimpleProduct[] | undefined = this.props.data.allProductVariants?.result;
        if (variantsResp) {
            for (let variantIter = 0; variantIter < variantsResp.length; variantIter++) {
                if (variantsResp[variantIter].RecordId === RecordId) {
                    const ePropsVariant = variantsResp[variantIter].ExtensionProperties!;
                    if (ePropsVariant?.length > 0) {
                        for (let eIter = 0; eIter < ePropsVariant?.length; eIter++) {
                            if (ePropsVariant[eIter].Key === 'RetailVariantId') {
                                variantId = ePropsVariant[eIter].Value?.StringValue;
                                break;
                            }
                        }
                    }
                }
            }
        } else {
            const variantofId = `V000${ItemId}`;
            variantId = variantofId?.slice(0, -1);
        }
        return variantId;
    };

    private _getProductAttributeUpdated = (productSpecificationData: AttributeValue[], props: IBuyboxModuleProps<IMfrmBuyboxData>) => {
        // shippingInformation product attribute value
        const shippingInformationSmall = productSpecificationData
            .find(attr => attr.Name?.trim() === props.context.app.config.smallParcelAttributeKey)
            ?.TextValue?.toLowerCase();

        return this._getProductType(shippingInformationSmall);
    };

    //private async methods
    // tslint:disable-next-line: max-line-length
    private _updateAvailbilityForVariantsUpdated = async (
        allVariants: IBuyboxGetDimensionVariantAvailabilityActionData[],
        props: IBuyboxModuleProps<IMfrmBuyboxData>,
        productSpecificationData: AttributeValue[]
    ) => {
        const variantsResult: IBuyboxGetDimensionVariantAvailabilityActionData[] = allVariants;
        const availableVariantIDs: number[] = [];
        const itemId = props?.data?.product?.result?.ItemId;
        const {
            context: {
                app: {
                    config: {
                        eComAllVariantsOOSMultiAttr,
                        ecomAllVairantStockInfoKey,
                        ecomAllVairantStockInfoKey1,
                        ecomAllVairantStockInfoKey2,
                        ecomAllVairantStockInfoKey3
                    }
                }
            }
        } = this.props;
        let count = 0;
        if (this.state.masterProductId) {
            //calling this to get masterProductAttribute data when PDP is loading from variantId
            productSpecificationData = await getAttributeValuesAsync(
                { callerContext: props.context.actionContext, queryResultSettings: {} },
                this.state.masterProductId,
                props.context.request.apiSettings.channelId,
                0
            );
        }
        let ecomAllVairantStockInfoConcatenated: any[] = [];
        // for backward compatiblity
        if (!eComAllVariantsOOSMultiAttr || eComAllVariantsOOSMultiAttr === undefined) {
            try {
                const eComJsonData = productSpecificationData.find(attr => attr.Name?.trim() === ecomAllVairantStockInfoKey)?.TextValue;
                const newAllVariantStockInfo = eComJsonData && JSON.parse(eComJsonData);
                ecomAllVairantStockInfoConcatenated = [
                    ...ecomAllVairantStockInfoConcatenated,
                    ...newAllVariantStockInfo.VariantList.map(
                        (item: IVariantStockInfo) => `${item.VariantRecID}|${item.OutOfStock ? '1' : '0'}`
                    )
                ];
            } catch (error) {
                this.telemetryContent.telemetry?.error(`Error ${error} [OOS Checking] ecomAllVairantStockInfoKey`);
                console.log(`Error ${error} [OOS Checking] ecomAllVairantStockInfoKey`);
            }
        } else {
            const ecomVairantStockInfoKey1 = ecomAllVairantStockInfoKey1 ? ecomAllVairantStockInfoKey1 : 'EcomAllVariantOOSInfo1';
            const ecomVairantStockInfoKey2 = ecomAllVairantStockInfoKey2 ? ecomAllVairantStockInfoKey2 : 'EcomAllVariantOOSInfo2';
            const ecomVairantStockInfoKey3 = ecomAllVairantStockInfoKey3 ? ecomAllVairantStockInfoKey3 : 'EcomAllVariantOOSInfo3';
            const ecomAllVairantStockInfo1 = productSpecificationData.find(attr => attr.Name?.trim() === ecomVairantStockInfoKey1)
                ?.TextValue;
            const ecomAllVairantStockInfo2 = productSpecificationData.find(attr => attr.Name?.trim() === ecomVairantStockInfoKey2)
                ?.TextValue;
            const ecomAllVairantStockInfo3 = productSpecificationData.find(attr => attr.Name?.trim() === ecomVairantStockInfoKey3)
                ?.TextValue;

            if (ecomAllVairantStockInfo1) {
                try {
                    ecomAllVairantStockInfoConcatenated = [...ecomAllVairantStockInfoConcatenated, ...JSON.parse(ecomAllVairantStockInfo1)];
                } catch (error) {
                    this.telemetryContent.telemetry?.error(`Error ${error} [OOS Checking] ecomAllVairantStockInfoKey1`);
                    console.log(`Error ${error} [OOS Checking] ecomAllVairantStockInfoKey1`);
                }
            }
            if (ecomAllVairantStockInfo2) {
                try {
                    ecomAllVairantStockInfoConcatenated = [...ecomAllVairantStockInfoConcatenated, ...JSON.parse(ecomAllVairantStockInfo2)];
                } catch (error) {
                    this.telemetryContent.telemetry?.error(`Error ${error} [OOS Checking] ecomAllVairantStockInfoKey2`);
                    console.log(`Error ${error} [OOS Checking] ecomAllVairantStockInfoKey2`);
                }
            }
            if (ecomAllVairantStockInfo3) {
                try {
                    ecomAllVairantStockInfoConcatenated = [...ecomAllVairantStockInfoConcatenated, ...JSON.parse(ecomAllVairantStockInfo3)];
                } catch (error) {
                    this.telemetryContent.telemetry?.error(`Error ${error} [OOS Checking] ecomAllVairantStockInfoKey3`);
                    console.log(`Error ${error} [OOS Checking] ecomAllVairantStockInfoKey3`);
                }
            }
        }
        this.setState({ ecomAllVairantStockInfoConcatenated });
        try {
            variantsResult &&
                variantsResult.forEach(async variant => {
                    // get specfication data for each variant
                    count++;
                    if (!productSpecificationData) {
                        return;
                    }
                    let outOfStockBoolean = null;
                    for (let i = 0; i < ecomAllVairantStockInfoConcatenated.length; i++) {
                        if (
                            ecomAllVairantStockInfoConcatenated &&
                            ecomAllVairantStockInfoConcatenated[i].split('|')[0] === variant.variantID.toString()
                        ) {
                            outOfStockBoolean = !!Number(ecomAllVairantStockInfoConcatenated[i].split('|')[1]);
                            break;
                        }
                    }
                    outOfStockBoolean ? (variant.isAvailable = false) : (variant.isAvailable = true);
                    if (outOfStockBoolean === null || outOfStockBoolean === undefined) {
                        variant.isAvailable = false;
                    }
                    // get product type for each
                    const productType = this._getProductAttributeUpdated(productSpecificationData, props);
                    // set availability for each
                    if (productType === 'Core') {
                        if (outOfStockBoolean) {
                            variant.isAvailable = false;
                        } else {
                            variant.isAvailable = true;
                        }
                    } else if (productType === 'Small Parcel') {
                        if (outOfStockBoolean) {
                            variant.isAvailable = false;
                        } else {
                            availableVariantIDs.push(variant.variantID);
                            variant.isAvailable = true;
                        }
                    }
                    else if (productType === 'Drop Ship') {
                        if (outOfStockBoolean) {
                            variant.isAvailable = false;
                        } else {
                            variant.isAvailable = true;
                        }
                    }
                    // update the state
                    if (availableVariantIDs.length > 0 && count === variantsResult.length) {
                        const batchedAtp = await _coreProductATPCallForInventoryStatusForAllVariants(
                            props,
                            availableVariantIDs,
                            itemId,
                            variantsResult
                        );
                        this.setState({ batchedAtpInventoryStatusData: batchedAtp });
                        await this.setDefaultOOSVariant(variantsResult);
                    } else if (count === variantsResult.length) {
                        await this.setDefaultOOSVariant(variantsResult);
                    }

                    this.setState({ variantAvailibilityList: variantsResult });
                });
        } catch (error) {
            console.error("Variant result is not defined or empty", error);
        }

    };

    private _updateUtagData = async (updatedProduct: SimpleProduct | undefined, updatedPrice?: number) => {
        const variantId = await this.getVariantID(updatedProduct);
        const price = await this.props.data.productPrice;
        const finalPrice = updatedPrice ? updatedPrice : price?.CustomerContextualPrice;
        try {
            // @ts-ignore
            if (utag.data) {
                // @ts-ignore
                utag.data = {
                    // @ts-ignore
                    ...utag.data,
                    product_price: [`${finalPrice}`],
                    product_variant: [`${variantId}`],
                    product_url: [getProductURLForUtag(updatedProduct, this.props.context)],
                    product_image_url: [`${updatedProduct?.PrimaryImageUrl}`],
                    product_size: [getProductSizeForUTag(updatedProduct)]
                };
            }
            // @ts-ignore
            if (utag_data) {
                // @ts-ignore
                utag_data = {
                    // @ts-ignore
                    ...utag_data,
                    product_price: [`${finalPrice}`],
                    product_variant: [`${variantId}`],
                    product_url: [getProductURLForUtag(updatedProduct, this.props.context)],
                    product_image_url: [`${updatedProduct?.PrimaryImageUrl}`],
                    product_size: [getProductSizeForUTag(updatedProduct)]
                };
            }
        } catch (error) {
            console.log(error, '[utag_data not found]');
        }
    };

    private _sendProductDataToTealium = async (props: IBuyboxModuleProps<IMfrmBuyboxData>, product: SimpleProduct | undefined) => {
        const {
            data: {
                productSpecificationData: { result: productSpecificationData },
                productPrice,
                categoryPaths
            },
            context: {
                request: { }
            },
            app: {
                config: { brandBackofficeAttributePdp }
            }
        } = props;
        this.setState({ tealiumRecordIdFor: product?.RecordId.toString() });
        const allCategoriesArr = categoryPaths?.result;
        const categoryObj = allCategoriesArr && allCategoriesArr[allCategoriesArr?.length - 1];
        const categoryName: string | undefined = categoryObj ? `${categoryObj?.Name}` : undefined;
        const fallBackBrandAttribute = 'VENDOR BRAND';
        const attribute = brandBackofficeAttributePdp || fallBackBrandAttribute;
        let productBrandName =
            productSpecificationData &&
            productSpecificationData?.find(
                currAttribute => currAttribute.Name && currAttribute.Name.toLowerCase().trim() === attribute.toLowerCase().trim()
            )?.TextValue;
        productBrandName = productBrandName ? `${productBrandName}` : undefined;
        const variantsResp = await this.getVariantID();
        // @ts-ignore
        if ('utag' in window) {
            const { ItemId, Name } = product || {};
            // @ts-ignore
            // tslint:disable-next-line: object-literal-key-quotes
            utag.link({
                event: 'product-detail-view',
                event_category: 'ecommerce',
                event_action: 'product detail view',
                enhanced_action: 'detail',
                event_label: `${ItemId}:${Name}`,
                event_noninteraction: 'true',
                product_id: [`${ItemId}`],
                product_name: [`${Name}`],
                product_category: [categoryName],
                product_brand: [productBrandName],
                product_variant: [variantsResp],
                product_price: [`${(await productPrice)?.CustomerContextualPrice}`],
                product_url: [getProductURLForUtag(product, this.props.context)],
                product_image_url: [`${product?.PrimaryImageUrl}`],
                product_size: [getProductSizeForUTag(product)]
            });
        }
        // VSI - Customization Start - 47480
        this.setState({ variantIdForTracking: variantsResp ? variantsResp : '' });
        // VSI - Customization End - 47480
    };

    private async setDefaultOOSVariant(
        variantAvailibilityList: IBuyboxGetDimensionVariantAvailabilityActionData[],
        updatedProductSpecificationData?: AttributeValue[] | undefined
    ) {
        if (variantAvailibilityList?.length > 0) {
            const {
                data: {
                    product: { result: product },
                    allProductVariants: { result: allProductVariants },
                    productSpecificationData: { result: productSpecificationData },
                    productDimensions: { result: productDimensions },
                    dimensionPrices: { result: dimensionPrices }
                },
                context: {
                    actionContext,
                    request: {
                        apiSettings: { channelId }
                    }
                }
            } = this.props;
            const prodSpecficationData = updatedProductSpecificationData ? updatedProductSpecificationData : productSpecificationData;

            // VSI - Customization Start 42852
            const prices: IBuyboxGetDimensionPricingActionData[] | undefined =
                dimensionPrices && (await _getActivePrices(dimensionPrices, actionContext));
            let extendedAllProductVariants: ISimpleProductExtended[] | undefined =
                prices && prices[0].discountAmount ? this.extendAllProductVariants(allProductVariants, prices) : allProductVariants;
            extendedAllProductVariants =
                extendedAllProductVariants &&
                extendedAllProductVariants.sort(
                    (a, b) =>
                        (a?.priceAfterDiscount ? a?.priceAfterDiscount : a?.Price) -
                        (b?.priceAfterDiscount ? b?.priceAfterDiscount : b?.Price)
                );
            const updatedProduct = await this.getNextInventoryProduct(
                product,
                prodSpecficationData,
                extendedAllProductVariants,
                this.props.context,
                this.state.variantAvailibilityList ?? variantAvailibilityList
            );
            if (updatedProduct && updatedProduct.Dimensions && updatedProduct.Dimensions.length > 0) {
                const dimensions: ProductDimensionFull[] = [];
                for (const dimension of updatedProduct.Dimensions) {
                    const dimensionValues = await getDimensionValuesAsync(
                        { callerContext: actionContext, queryResultSettings: {} },
                        updatedProduct.MasterProductId ? updatedProduct.MasterProductId : updatedProduct.RecordId,
                        channelId,
                        dimension.DimensionTypeValue,
                        [],
                        null
                    );
                    const fullDimension = {
                        ...dimension,
                        DimensionValues: dimensionValues
                    };
                    dimensions.push(fullDimension);
                }
                this.setState({
                    currentProduct: updatedProduct,
                    currentProductDimensions: dimensions
                });
                // updated Product is a different product than product in props
                if (product?.RecordId !== updatedProduct?.RecordId) {
                    await this._updateDimensions(updatedProduct, dimensions);
                    await this._updateUtagData(updatedProduct);
                } else {
                    this._updateURLWithoutReloading(updatedProduct);
                }
            } else {
                console.log('updated product doesnt have dimesions=>');
                this.setState({
                    currentProduct: product,
                    currentProductDimensions: productDimensions
                });
                this._updateURLWithoutReloading(product);
                await this._sendProductDataToTealium(this.props, product);
            }
        }
    }

    private async _isOrderQuantityLimitsFeatureEnabled(): Promise<boolean> {
        const defaultOrderQuantityLimitsFeatureConfig = this.props.context?.request?.app?.platform?.enableDefaultOrderQuantityLimits;
        if (defaultOrderQuantityLimitsFeatureConfig === 'none') {
            return false;
        }

        if (defaultOrderQuantityLimitsFeatureConfig === 'all') {
            return true;
        }
        let customerInfo;
        try {
            customerInfo = await this.props.data.customerInformation;
        } catch (error) {
            // this.props.telemetry.information(error);
            this.props.telemetry.debug('Unable to receive Customer Information. May be user is not authorized');
            return false;
        }

        return (
            customerInfo &&
            ((defaultOrderQuantityLimitsFeatureConfig === 'b2b' && customerInfo.IsB2b) ||
                (defaultOrderQuantityLimitsFeatureConfig === 'b2c' && !customerInfo.IsB2b))
        );
    }

    private async _updateQuantitiesInState(product: SimpleProduct): Promise<void> {
        const isOrderQuantityLimitsFeatureEnabled = await this._isOrderQuantityLimitsFeatureEnabled();
        if (isOrderQuantityLimitsFeatureEnabled && product) {
            this.setState({
                quantity: product.Behavior?.DefaultQuantity || 1,
                min: product.Behavior?.MinimumQuantity || 1,
                // If max by feature in default order settings is not defined then use max from site settings or default max 10.
                max:
                    product.Behavior?.MaximumQuantity && product.Behavior?.MaximumQuantity > 0
                        ? product.Behavior?.MaximumQuantity
                        : this.props.context.app.config.maxQuantityForCartLineItem || 10
            });
        } else {
            this.setState({
                min: 1,
                max: this.props.context.app.config.maxQuantityForCartLineItem || 10
            });
        }
    }

    private async _updateVariantIDForTracking(): Promise<void> {
        const variantId = await this.getVariantID();
        this.setState({ variantIdForTracking: variantId ? variantId : '' });
    }

    private _eyeBrowMessageAsyncFunc = async (isCached: boolean): Promise<void> => {
        const {
            context,
            data: {
                product: { result }
            }
        } = this.props;
        const priceGroupForAPICall = context.app.config.priceGroup;
        if (priceGroupForAPICall) {
            const productIdsArray: IMFIItem[] | undefined = [];
            const eyebrowMessageObj: IMFIItem = {
                ItemId: result?.ItemId === undefined ? '' : result?.ItemId,
                DistinctProductVariant: result?.RecordId?.toString()
            };
            productIdsArray.push(eyebrowMessageObj);
            if (productIdsArray && productIdsArray.length > 0) {
                const eyebrowMessageResult = _getEyebrowMessage(productIdsArray, context, isCached, 'pdp');
                eyebrowMessageResult
                    ?.then((data: IMFIPromotion[] | null) => {
                        this.setState({ eyebrowMessageResultState: data, isEyeBrowLoading: false });
                    })
                    .catch(e => {
                        this.setState({ isEyeBrowLoading: false });
                        console.log('Error', e);
                    });
            }
        } else {
            this.setState({ isEyeBrowLoading: false });
        }
    };

    private getDeliverySlotFromATP = async (isCached: boolean): Promise<void> => {
        const itemLinesArray = [];
        const {
            data: {
                product: { result: product }
            },
            context: {
                app: {
                    config: { weeksPDPforATPCall }
                }
            }
        } = this.props;
        const cookies = new Cookies();
        const zipcode = cookies.get('zip_code');
        if (product) {
            const itemLines: IMFICartLine = {
                ItemId: product.ItemId,
                Quantity: 1,
                VariantRecordId: product.RecordId.toString()
            };
            itemLinesArray.push(itemLines);
            const currentDate = Date.now();
            const requestBody: IMFICLDeliveryScheduleParameters = {
                InventoryType: 'Delivery',
                Weeks: weeksPDPforATPCall,
                StoreId: '',
                Page: 'pdp',
                RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
                ZipCode: zipcode,
                ItemLines: itemLinesArray
            };
            _getDeliveryByMessage(requestBody, this.props.context, isCached)
                .then(result => {
                    if (result !== undefined && result?.ATPInventoryData) {
                        // setCoreProductATPData(result?.ATPInventoryData);
                        this.setState({
                            deliveryMessageATPResultState: result?.ATPInventoryData
                        });
                    }
                })
                .catch(e => {
                    console.log('Error', e);
                });
        }
    };

    private _tryInStoreAsyncCall = async () => {
        const {
            context: { actionContext, app },
            data: {
                product: { result: product }
            }
        } = this.props;
        // VSI Customization - Start
        const isSociEnabled = this.props.app.config.enableSociLocations || false;
        const preferredStore = this._getPreferredStore();
        const sociPreferredStore = this._getSociPreferredStore();

        // Todo: Zip code is being modified as we are not getting proper zip code.. It will be reverted back as it get modified/validate from backoffice
        const zipCodeModified = preferredStore && (!isSociEnabled ? preferredStore.Zip?.split('-')[0] : preferredStore?.Zip);
        const projectMetaSoci = isSociEnabled && sociPreferredStore?.project_meta ? JSON.parse(sociPreferredStore?.project_meta) : '';
        const sociStore = projectMetaSoci ? projectMetaSoci['Corporate ID'] : '';
        const sociStoreID = sociStore && (sociStore?.length > 6 ? sociStore : `000000${sociStore}`?.slice(-6));
        const StoreId = isSociEnabled ? sociStoreID : preferredStore?.OrgUnitNumber!;
        if (StoreId && zipCodeModified && product?.ItemId?.toString()) {
            const tryInStoreProductIDs: IMFITryInStore = {
                ZipCode: zipCodeModified,
                StoreId: isSociEnabled ? sociStoreID : preferredStore?.OrgUnitNumber!,
                Products: product?.ItemId?.toString(),
                Limit: app.config.tryInStoreLimit || 10,
                Radius: app.config.tryInStoreLookupRadius || 50
            };
            const getTryInStoreInfoInput = new GetTryInStoreInfoInput(tryInStoreProductIDs);
            try {
                const response = await getTryInStoreAction(getTryInStoreInfoInput, actionContext);
                this.setState({
                    tryInStoresResults: response,
                    OrgUnitNumber: isSociEnabled ? sociStoreID : preferredStore && preferredStore.OrgUnitNumber ? preferredStore.OrgUnitNumber : '0',
                    isStoreLoading: false
                });
            } catch (error) {
                this.setState({ isStoreLoading: false });
                console.log(error);
            }
        }
    };
    // tslint:disable-next-line:max-func-body-length
    private _updateDimensions = async (updatedProduct?: SimpleProduct, dimensions?: ProductDimensionFull[] | undefined): Promise<void> => {
        const {
            data: {
                product: { result: prevProduct },
                productDimensions: { result: dimensionsArr }
            },
            context: {
                actionContext,
                request: {
                    apiSettings: { channelId }
                }
            }
        } = this.props;
        const product = updatedProduct ? updatedProduct : prevProduct;
        const productDimensions = dimensions ? dimensions : dimensionsArr;
        if (!product || !productDimensions) {
            return;
        }

        const dimensionsToUpdate: { [id: number]: string } = { ...this.dimensions };
        // this._setSmallParcelATPCallMade(true);
        this.setState({ isUpdatingDimension: true, isUpdatingDeliveryOptions: true });
        // Step 1: Clear error state to display relevant errors
        if (this.state.errorState.otherError || this.state.errorState.quantityError) {
            const clearErrorState = { ...this.state.errorState };
            clearErrorState.otherError = undefined;
            if (this.state.errorState.errorHost === 'ADDTOCART') {
                clearErrorState.quantityError = undefined;
                clearErrorState.errorHost = undefined;
            }
            this.setState({ errorState: clearErrorState });
        }

        // Step 2: Clear any errors indicating the dimension wasn't selected
        for (const key of Object.keys(dimensionsToUpdate)) {
            if (this.state.errorState.configureErrors[key]) {
                const errorState = { ...this.state.errorState };
                errorState.configureErrors[key] = undefined;

                this.setState({ errorState: errorState });
            }
        }

        // Step 3, Build the actually selected dimensions, prioritizing the information in state
        // over the information in data
        const nextProductDimensions: ProductDimensionFull[] = [];
        const mappedDimensions =
            productDimensions?.length > 0
                ? productDimensions
                    ?.map(dimension => {
                        const obj = dimension && {
                            DimensionTypeValue: dimension.DimensionTypeValue,
                            DimensionValue:
                                this._updateDimensionValue(dimension, dimensionsToUpdate[dimension.DimensionTypeValue]) ??
                                dimension.DimensionValue ?? product.Dimensions?.find(dim => dim.DimensionTypeValue === dimension.DimensionTypeValue)?.DimensionValue,
                            ExtensionProperties: dimension.ExtensionProperties,
                            DimensionValues: dimension.DimensionValues
                        };
                        nextProductDimensions.push(obj);
                        return (
                            dimension && {
                                DimensionTypeValue: dimension.DimensionTypeValue,
                                DimensionValue:
                                    this._updateDimensionValue(dimension, dimensionsToUpdate[dimension.DimensionTypeValue]) ??
                                    dimension.DimensionValue ?? product.Dimensions?.find(dim => dim.DimensionTypeValue === dimension.DimensionTypeValue)?.DimensionValue,
                                ExtensionProperties: dimension.ExtensionProperties
                            }
                        );
                    })
                    .filter(dimension => {
                        return dimension?.DimensionValue;
                    })
                : [];
        const variants = getVariantsByDimensionValuesAsync(
            { callerContext: actionContext, queryResultSettings: {} },
            product.MasterProductId ? product.MasterProductId : product.RecordId,
            channelId,
            mappedDimensions
        );

        // Step 4. Use these dimensions hydrate the product. Wrap this in a promise
        // so that places like add to cart can await it
        const selectedProduct = await variants;
        this.props.context.app.config.queryBasedVariantSelection && this._updateQueryParamVariantId(selectedProduct[0]?.RecordId);

        this.setState({ selectedProduct: variants[0] });
        const variantProduct = selectedProduct[0];

        if (variantProduct) {
            // Step 5. Use these dimensions hydrate the inventory. Wrap this in a promise
            // so that places like add to cart can await it
            const newAvailableQuantity = await getProductAvailabilitiesForSelectedVariant(
                new ProductAvailabilitiesForSelectedVariantInput(variantProduct.RecordId, channelId),
                actionContext
            );

            const isCustompriceSelected =
                variantProduct &&
                variantProduct.Dimensions &&
                variantProduct.Dimensions.find(
                    dimension =>
                        dimension.DimensionTypeValue === 4 && dimension.DimensionValue && dimension.DimensionValue.Value === 'Custom'
                );
            if (isCustompriceSelected) {
                this.setState({ isCustomPriceSelected: true });
            } else {
                // Remove custom amount error when unselect the custom amount
                const errorState = { ...this.state.errorState };
                errorState.customAmountError = undefined;

                this.setState({ isCustomPriceSelected: false, isPriceKeyedIn: false, errorState: errorState });
            }

            if (newAvailableQuantity?.length) {
                this.setState({ productAvailableQuantity: newAvailableQuantity[0] });
            } else {
                this.setState({ productAvailableQuantity: undefined });
            }

            // Step 6. Use these dimensions hydrate the product price.
            const newPrice = await getPriceForSelectedVariant(
                new PriceForSelectedVariantInput(variantProduct.RecordId, channelId),
                actionContext
            );

            if (newPrice) {
                this._updatePrice(newPrice);
            }

            // const RetailMulitplePickupMFeatureState = this.props.data.featureState.result?.find(featureState => featureState.Name === 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature');
            // Step 7. Use these dimensions hydrate the product delivery options.
            const newDeliveryOptions = await getDeliveryOptionsForSelectedVariant(
                new GetDeliveryOptionsForSelectedVariantInput(
                    variantProduct.RecordId,
                    channelId,
                    undefined,
                    undefined
                    // RetailMulitplePickupMFeatureState?.IsEnabled
                ),
                actionContext
            );

            if (newDeliveryOptions) {
                this.setState({ productDeliveryOptions: newDeliveryOptions });
            }
            this.setState({
                currentProduct: variantProduct,
                currentProductDimensions: nextProductDimensions
            });
            await this._updateQuantitiesInState(variantProduct);
            await this.makeCoreProductDeliveryMessage('ATP update dimnesions');
            // VSI - Customization Start - 47480
            await this._updateVariantIDForTracking();
            // VSI - Customization End - 47480
            // VSI - Customization Start - 27818
            // VSI - Customization End - 27818
            // VSI - Customization Start - 42674
            this._updateURLWithoutReloading(variantProduct);
            // VSI - Customization End - 42674
        }
    };

    private findPreferredStore = async (): Promise<void> => {
        const {
            data: {
                storeSelectorStateManager: { result: storeSelectorStateManager }
            },
            modules
        } = this.props;
        if (!storeSelectorStateManager) {
            return;
        }
        let storeSelectorId: string = '';
        if (modules && Object.keys(modules).length > 0 && modules.mfrmStoreSelector && modules.mfrmStoreSelector.length > 0) {
            storeSelectorId = modules.mfrmStoreSelector[0].id;
        }
        MsDyn365.isBrowser && document.body.classList.add('modal-store-locator-toggle-pdp');
        this.setState({ isStoreDetailsOpen: false });
        storeSelectorStateManager
            ?.openDialog({
                id: storeSelectorId,
                showMapViewLink: false,
                onLocationSelected: () => {
                    return Promise.resolve();
                }
            })
            .catch((error: Error) => {
                if (this.props.telemetry) {
                    this.props.telemetry.error(error.message);
                    this.props.telemetry.debug('Unable to set preferred store');
                }
                return;
            });
    };


    public render(): JSX.Element | null {
        const {
            slots: { mediaGallery },
            data: {
                product: { result: product },
                productSpecificationData: { result: productSpecificationData },
                selectedFoundation: { result: adjustableItem },
                completeYourBedPillow: { result: pillow },
                completeYourBedProtector: { result: protector }
            },
            config: { className = '' },
            app: { config: { brandBackofficeAttributePdp } }
        } = this.props;
        const { max } = this.state;
        if (!product) {
            this.props.context.telemetry.error('Product content is empty, module wont render');
            return null;
        }
        const props = this.props;
        const defaultMinimumKeyInPrice = 10;
        const defaultMaximumKeyInPrice = 100;
        const brandName = parseBrandName(productSpecificationData, brandBackofficeAttributePdp) || '';

        const viewProps: IBuyboxViewPropsExtended = {
            ...this.props,
            state: this.state,
            mediaGallery: mediaGallery?.length > 0 ? mediaGallery[0] : undefined,
            ModuleProps: {
                moduleProps: this.props,
                className: classnames('ms-buybox', className)
            },
            ProductInfoContainerProps: {
                className: 'ms-buybox__content'
            },
            MediaGalleryContainerProps: {
                className: 'ms-buybox__media-gallery'
            },
            telemetryContent: this.telemetryContent,
            callbacks: this.buyboxCallbacks,
            title: getBuyboxProductTitle(props, undefined, brandName),
            description: getBuyboxProductDescription({ ...props, config: {} }),
            findInStore: getBuyboxFindInStore(this.props, this.state, this.buyboxCallbacks),
            price: getBuyboxProductPrice({ ...props, config: {} }, this.state),
            addToCart: getBuyboxAddToCart(props, this.state, this.buyboxCallbacks, defaultMinimumKeyInPrice, defaultMaximumKeyInPrice),
            viewCartLink: getUrlSync('cart', props.context.actionContext),
            inventoryLabel: getBuyBoxInventoryLabel(props),
            shopSimilarLook:
                this.props.config.enableShopSimilarLooks
                && !product.IsGiftCard
                && <BuyboxShopSimilarItems {...getBuyboxShopSimilarButton({ ...props, config: {} }, ShopSimiliarButtonType.Looks)} />,
            keyInPrice:
                this.props.config.enableKeyInPrice && this.state.isCustomPriceSelected
                && <BuyboxKeyInPrice {...getBuyboxKeyInPrice({ ...props, config: {} }, this.state, this.buyboxCallbacks)} />,
            quantityLimitsMessages: getQuantityLimitsMessages(props, this.state),
            max: max,
            smallParcelATCallMade: this.state.smallParcelATCallMade,
            setSmallParcelATPCallMade: this._setSmallParcelATPCallMade,
            StatusATPCall: this.state.StatusATPCall,
            setStatusATPCall: this._setStatusATPCall,
            setZipCode: this._setZipCode,
            ATPCall: this.state.ATPCall,
            setATPCall: this._setATPCall,
            extensionProps: this.state.extensionProps,
            productBadges:
                props.app.config.productBadgeAttribute &&
                this.props.data.productSpecificationData?.result?.length &&
                _getProductBadges(this.props.context, this.props.data.productSpecificationData.result),
            ratingComponent: <BuyboxProductRating
                hideRating={props.context.app.config?.hideRating || false}
                isBazaarVoice={this.props.config.showBVRatings}
                itemId={props.data.product.result?.ItemId}
                variantId={props.data.product.result?.RecordId}
                id={props.id}
                typeName={props.typeName}
                context={props.context}
                productSpecification={props.data.productSpecificationData.result}
                resources={props.resources}
            />,
            tryInStoreComponent: <BuyboxTryInStoreRedesign
                isSociEnabled={props.app.config.enableSociLocations}
                preferredStore={this._getPreferredStore()}
                sociPrefferedStore={this._getSociPreferredStore()}
                isOnlineOnly={this._getProductSpecificationAttributeByKeyName(this.props.context.app.config?.onlineOnlyBackOfficeAttribute)}
                canTryInStores={this._getProductSpecificationAttributeByKeyName('try in store')}
                storeHoursClosedText={props.resources.storeHoursClosedText}
                storeCloseAtHours={props.resources.storeCloseAtHours}
                storeOpenAtHours={props.resources.storeOpenAtHours}
                availableInStoresTextV2={props.resources.availableInStoresTextV2}
                notAvailableInStoresTextV2={props.resources.notAvailableInStoresTextV2}
                viewStoreDetailsText={props.resources.viewStoreDetailsText}
                changeStoreText={props.resources.changeStoreText}
                tryInStoresResults={this.state.tryInStoresResults}
                isStoreDetailsOpen={this.state.isStoreDetailsOpen}
                onClickChangeStore={() => this.findPreferredStore()}
                onClickNameOrDetails={async (isAvailable) => {
                    if (isAvailable) {
                        this.setState(prevState => ({ isStoreDetailsOpen: !prevState.isStoreDetailsOpen }));
                    } else {
                        await this.findPreferredStore();
                    }
                }}
                onClickOutside={() => {
                    this.setState({ isStoreDetailsOpen: false });
                }}
            />,
            promotionalMessage: this.state?.eyebrowMessageResultState?.[0]?.EyeBrows?.[0].Description?.split("|")[1],
            deliveryMessage: this.state.deliveryMessage,
            ATPDataFromBusiness: this.state.deliveryMessageATPResultState,
            updateDimension: this.state.isUpdatingDimension,
            showViewCartLink: this.state.showViewCartLink,
            updateSuccessfulATC: this.updateSuccessfulATC,
            getProductType: this._getProductType,
            setAllProductVariantsResult: this._setAllProductVariantsResult,
            ecomAllVairantStockInfoConcatenated: this.state.ecomAllVairantStockInfoConcatenated,
            customerSatisfactionScore: <BuyboxCustomerSatisfactionScore
                unitsReturned={getProductSpecNumber(props.data.productSpecificationData, 'units_returned')}
                unitsSold={getProductSpecNumber(props.data.productSpecificationData, 'units_sold')}
                //TODO these must be string replaced inside of the component
                headingText={props.config.cSatRatingHeadingText}
                popoverText={props.config.cSatRatingPopoverText}
                tooltipHover={props.isDesktop}
            />,
            adjustableItem,
            pillow,
            protector
        };
        return this.props.renderView(viewProps);
    }
}

export default withIsDesktop(Buybox);