import React from "react";
import { Node } from '@msdyn365-commerce-modules/utilities';

interface OpenProps {
    children: React.ReactNode;
    isOpen: boolean;
}

export const Open = React.forwardRef<HTMLElement, OpenProps>(({ isOpen, children }: OpenProps, ref) => {
    if (isOpen) {
        return (
            <Node className={'ms-buybox__try-in-store--v2__open'} ref={ref}>
                {children}
            </Node>
        );
    } else {
        return <>{children}</>;
    }
});
