import React from 'react';
import { Node } from '@msdyn365-commerce-modules/utilities';
import useClickOutside from '../../../../common/hooks/useClickOutside';
import { BuyboxStoreDetails } from '../buybox-store-details/buybox-store-details';
import { extractPhoneNumber } from '../../../mfrm-store-selector/components/store-selector-location-line-item';
import { Open } from './open';
import { BuyboxTryInStoreRedesignProps } from './types';

export const BuyboxTryInStoreRedesign = ({
    isSociEnabled,
    preferredStore,
    sociPrefferedStore,
    isOnlineOnly,
    availableInStoresTextV2,
    notAvailableInStoresTextV2,
    viewStoreDetailsText,
    changeStoreText,
    canTryInStores,
    onClickChangeStore,
    tryInStoresResults,
    isStoreDetailsOpen,
    storeHoursClosedText,
    storeCloseAtHours,
    storeOpenAtHours,
    onClickNameOrDetails,
    onClickOutside
}: BuyboxTryInStoreRedesignProps): JSX.Element | null => {
    const ref = useClickOutside({
        onClickOutside: event => {
            if (isStoreDetailsOpen) {
                onClickOutside(event);
            }
        }
    });

    if (Object.keys(sociPrefferedStore)?.length === 0 && Object.keys(preferredStore)?.length === 0) {
        return null;
    }
    const sociStoreName = (isSociEnabled && sociPrefferedStore?.name) || '';
    const storeName: string | undefined =
        preferredStore &&
        (preferredStore.ExtensionProperties?.find(x => x.Key === 'Yext_Store_Name')?.Value?.StringValue || preferredStore?.OrgUnitName);
    const storeNameWithState = isSociEnabled ? `${sociStoreName}, ${sociPrefferedStore?.state}` : `${storeName}, ${preferredStore?.State}`;
    const { Inventory, Status } = { ...tryInStoresResults };
    const isAvailable = !!(Status === 'Success' && canTryInStores && Inventory?.length !== 0);

    if (
        !isAvailable &&
        !(Status === 'Success' && canTryInStores && Inventory?.length === 0) &&
        !(storeName && !canTryInStores && isOnlineOnly)
    ) {
        return null;
    }

    const storeLocatorContext =
        preferredStore && preferredStore.Contacts ? preferredStore.Contacts.find(url => url.ContactTypeValue === 3) : undefined;

    // VSI Customization - ends
    return (
        <Node className={'ms-buybox__try-in-store--v2'}>
            <Open isOpen={isStoreDetailsOpen} ref={ref}>
                <Node className={`ms-buybox__try-in-store--v2__${isAvailable ? 'available' : 'unavailable'}`}>
                    <Node className='ms-buybox__try-in-store--v2-marker' />
                    <Node className='ms-buybox__try-in-store--v2_text-left' tag='p'>
                        {isAvailable ? availableInStoresTextV2 : notAvailableInStoresTextV2}
                        <Node
                            className='ms-buybox__try-in-store--v2_link'
                            tag='a'
                            role='button'
                            tabIndex='0'
                            aria-pressed='false'
                            onClick={() => onClickNameOrDetails(isAvailable)}
                            href='javascript:void(0)'
                        >
                            {storeNameWithState}
                        </Node>
                    </Node>
                </Node>
                {isStoreDetailsOpen && (
                    <BuyboxStoreDetails
                        isSociEnabled={isSociEnabled}
                        sociPrefferedStore={sociPrefferedStore}
                        storeAddressLine1={isSociEnabled && sociPrefferedStore ? sociPrefferedStore.address1 : preferredStore?.Street}
                        storeAddressLine2={
                            isSociEnabled && sociPrefferedStore
                                ? sociPrefferedStore.address2
                                : `${preferredStore?.City}, ${preferredStore?.State} ${preferredStore?.Zip}`
                        }
                        phoneNumber={isSociEnabled ? sociPrefferedStore?.phone : extractPhoneNumber(preferredStore?.Contacts)}
                        preferredStore={preferredStore}
                        storeHoursClosedText={storeHoursClosedText}
                        storeCloseAtHours={storeCloseAtHours}
                        sociStoreLocatorUrl={sociPrefferedStore?.website_url || ''}
                        storeLocatorUrl={
                            storeLocatorContext
                                ? storeLocatorContext.Locator
                                : `https://bing.com/maps/default.aspx?where1=${preferredStore?.Address}`
                        }
                        storeOpenAtHours={storeOpenAtHours}
                        onClickNameOrDetails={() => onClickNameOrDetails(isAvailable)}
                        viewStoreDetailsText={viewStoreDetailsText}
                        onClickChangeStore={onClickChangeStore}
                        changeStoreText={changeStoreText}
                    />
                )}
            </Open>
        </Node>
    );
};

BuyboxTryInStoreRedesign.displayName = 'BuyboxTryInStoreRedesign';
