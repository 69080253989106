import { AttributeValue } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { buildProductBadge, parseBadgeData } from './get-product-badge';
import { ICoreContext } from '@msdyn365-commerce/core';
import React from 'react';

export const _getProductBadges = (
    context: ICoreContext<{
        [x: string]: any;
    }>,
    productAttributes: AttributeValue[]
): JSX.Element | undefined => {
    // find the configured backend attribute
    const {
        request,
        actionContext: { requestContext: { app: { config: { productBadgeAttribute: attribute } } } }
    } = context;

    const badgeData =
        attribute &&
        productAttributes?.find(
            currAttribute => currAttribute.Name && currAttribute.Name.toLowerCase().trim() === attribute.toLowerCase().trim()
        )?.TextValue;

    if (!badgeData) {
        return;
    }

    // split badge data into its separate badges
    const badges = badgeData?.split(',');

    // parse badge data to get its schedule and image src
    const parsedBadges = badges?.map((badge: string) => parseBadgeData(badge, request));

    // build product badge image components
    // tslint:disable-next-line: no-any
    const finalizedBadges =
        parsedBadges && parsedBadges.map((parsedBadge: any) => parsedBadge && buildProductBadge(parsedBadge, request));

    return finalizedBadges && <>{finalizedBadges}</>;
};